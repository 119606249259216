import loginHulLogo from "../../assets/images/loginPage/loginHulLogo.svg";
import loginSamarth from "../../assets/images/samarth-logo.png";
import headerHulLogo from "../../assets/images/header/headerHulLogo.svg";
import samarthrHulLogo from "../../assets/images/samarth-logo.png";

const title = window.location.hostname !== "localhost" ? process.env.REACT_APP_ENV === 'production' ? "Samarth" : "Samarth-" + process.env.REACT_APP_ENV : 'Samarth-local env';

const constants = {
    applicationTitle: "Supply Chain Nerve Centre",
    loginSamarthogo: {
      name: "Samarth",
      path: loginSamarth,
    },
    routeToLandingPage: "/home/landing",
    welcomeMessage: "Welcome!",
    loginButtonText: "LOGIN",
    loginHulLogo: {
      name: "HUL Logo",
      path: loginHulLogo,
    },
    headerTitle: title,
    headerUserProfileGreetingMessage: "Hello ",
    headerHulLogo: {
      name: "HUL",
      path: headerHulLogo,
    },
    samarthHulLogo: {
      name: "Samarth",
      path: samarthrHulLogo,
    },
    addToActionCartTitle: "Action Cart",
    resetFilterTitle: "Reset Filter",
    executeActionsButtonDisplayText: "Execute Action",
    actionCartTitleText: "Action Cart",
    okButtonDisplayText: "Ok",
    previousRoute: -1,
    routeToAlertDetailsPage: "/samarth/alert_details",
};
  
export default constants;
  