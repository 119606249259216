import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LTCPContextProvider } from "LTCP Module/LTCPContext";
import LTCPLayout from "LTCP Module/components/LTCPLayout";
import LoadingWidget from "LTCP Module/components/LoadingWidget";
import LandingPage from "LTCP Module/pages/LandingPage";
import AlertsPage from "LTCP Module/pages/AlertsPage";
import DashboardPage from "LTCP Module/pages/DashboardPage";
import CUPage from "LTCP Module/pages/CUPage";
import UploadPage from "LTCP Module/pages/UploadPage";
import CapacityPage from "LTCP Module/pages/CapacityPage";
import DemandPage from "LTCP Module/pages/DemandPage";
import ReportPage from "LTCP Module/pages/ReportPage";
import "./index.css";

const LTCPRouter = () => {
  const theme = createTheme({
    palette: {
      primary: {
        light: "#E6F0FF",
        main: "#1F36C7",
        dark: "#1F36C7",
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0px 2px 5px 0px rgba(214, 222, 248, 1)",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            fontSize: 12,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            padding: 0,
            width: 70,
            height: 24,
            maxHeight: 24,
            fontSize: 12,
            textTransform: "none",

            "&.Mui-selected": {
              backgroundColor: "#1F36C7",
              color: "#FFFFFF",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LTCPContextProvider>
        <LoadingWidget>
          <Routes>
            <Route path="landing" element={<LandingPage />} />
            <Route path="dashboard" element={<LTCPLayout />}>
              <Route path="alerts" element={<AlertsPage />} />
              <Route path="overview" element={<DashboardPage />} />
              <Route path="cu" element={<CUPage />} />
              <Route path="uploads" element={<UploadPage />} />
              <Route path="capacity" element={<CapacityPage />} />
              <Route path="demand" element={<DemandPage />} />
              <Route path="reports" element={<ReportPage />} />
            </Route>
          </Routes>
        </LoadingWidget>
      </LTCPContextProvider>
    </ThemeProvider>
  );
};

export default LTCPRouter;
