import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { LTCPContext } from "LTCP Module/LTCPContext";

function LoadingWidget({ children }) {
  const { loading } = useContext(LTCPContext);

  return (
    <>
      {children}
      {loading && (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            zIndex: 10000,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "#3333",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              px: 3,
              py: 2,
              gap: 1,
              borderRadius: 1,
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography fontSize={16} fontWeight="bold">
              Loading...
            </Typography>
            <Typography fontSize={12} color="#888">
              Fetching the data from the server. Please wait.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}

export default LoadingWidget;
