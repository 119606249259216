const url = window.location.hostname === "localhost" ? "http://127.0.0.1:8000" : process.env.REACT_APP_BACKEND_API_URL;

const config = {
  landingApi: url + "/api/landing",
  alertDetailApi: url + "/api/RMPMShortage",
  demandDetailApi: url + "/api/DemandSupply",
  keyInsight: url + "/api/keyInsights",
  executeAction: url + "/api/executeAction",
};

export default config;