import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DemandSupply from "../../pages/alertDeatils1/component/DemandSupply/InsightsPopup";
import SimulateScenario from "../../pages/alertDeatils1/component/SimulateScenario/SimulateScenarioContent";
import styles from "./popup.module.css";

const DemandPopup = ({ open, handleClose, type, alertId, materialId, plantCode, week }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xl" sx={{ height: 'auto' }}>
      <DialogTitle className={styles.dialogHeader} style={{ backgroundColor: type === "Insights" ? 'white' : '#E1ECFF' }}>
        {type === "Insights" ? <span className={styles.headerTitle}>Insights for {alertId} - {materialId}</span> : <span className={styles.headerTitle}>Simulate Scenario</span>}
        <CloseOutlinedIcon onClick={handleClose} className={styles.closeIcon} />
      </DialogTitle>
      <div className={styles.dialogContent}>
        {type === "Insights" ? <DemandSupply alertId={alertId} materialId={materialId} plantCode={plantCode} week={week} /> : <SimulateScenario />}
      </div>
    </Dialog>
  );
};

export default DemandPopup;
