import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ltcpRoutes from "../constants/ltcpRoutes";
import { useContext } from "react";
import { LTCPContext } from "../LTCPContext";

function LTCPLayout() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { demandFile } = useContext(LTCPContext);

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      position="relative"
      bgcolor={theme.palette.primary.light}
    >
      <AppBar elevation={0} position="static">
        <Toolbar
          variant="dense"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Stack direction="row" gap={3}>
            <img
              src="/unilever_logo.svg"
              onClick={() => navigate(ltcpRoutes.LANDING)}
            />
            <Typography
              fontWeight="bold"
              fontSize="21px"
              paddingLeft={3}
              sx={{ borderLeft: "1px solid #ffffff7f" }}
            >
              Capacity Planning
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => navigate(ltcpRoutes.LANDING)}
            >
              <HomeOutlinedIcon />
            </IconButton>
            <IconButton sx={{ color: "white" }}>
              <PersonOutlineOutlinedIcon />
            </IconButton>
          </Stack>
          <Box
            sx={{
              background: "#2E2E2E",
              color: "#fff",
              position: "absolute",
              height: 20,
              bottom: -20,
              right: 0,
              pl: 5,
              pr: 2,
              clipPath: "polygon(0 0, 100% 0%, 100% 100%, 20% 100%)",
            }}
          >
            <Typography fontSize={12}>Based on {demandFile}</Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        alignItems="stretch"
        flexDirection="column"
        flexGrow={1}
        sx={{ overflowY: "auto" }}
      >
        <Outlet />
      </Box>
      <Box
        sx={{
          height: 32,
          position: "absolute",
          top: "40vh",
          right: -26,
          transform: "rotate(270deg)",
          background: "linear-gradient(90deg, #1F36C7 0%, #1F6BC7 100%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          px: 1,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          cursor: "pointer",
        }}
        // onClick={() => navigate("/app/alerts")}
      >
        <WarningAmberIcon fontSize="small" sx={{ color: "#FFBD65" }} />
        <Typography color="white" fontSize={12}>
          ALERTS
        </Typography>
      </Box>
      <Box
        sx={{
          height: 32,
          position: "absolute",
          top: "60vh",
          right: -40,
          transform: "rotate(270deg)",
          background: "linear-gradient(90deg, #1F36C7 0%, #1F6BC7 100%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          px: 1,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          cursor: "pointer",
        }}
      >
        <GraphicEqIcon fontSize="small" sx={{ color: "#fff" }} />
        <Typography color="white" fontSize={12}>
          SIMULATION
        </Typography>
      </Box>
    </Box>
  );
}

export default LTCPLayout;
