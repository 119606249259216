import URL from "./apiURL";

/**
 * Function to call landing API
 * @returns 
 */
export async function landingAPI() {
  try {
    const response = await fetch(URL.landingApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}

/**
 * Function to call Alert Details
 * @returns 
 */
export async function alertDetailAPI() {

  try {
    const response = await fetch(URL.alertDetailApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}

/**
 * Function to call Demand Supply API
 * @param {Alert Id} alertId 
 * @returns 
 */
export async function demandSupplyAPI(alertId) {

  const requestObj = {
    "Alert Id": alertId
  };

  try {
    const response = await fetch(URL.demandDetailApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObj)
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}

/**
 * Function to call Key Insight API
 * @param {Material Number} materialId 
 * @returns 
 */
export async function keyInsightApi(materialId, plantCode, week) {

  const requestObj = {
    "Material Number": materialId,
    "Plant Code": plantCode,
    "week": week
  }
  try {
    const response = await fetch(URL.keyInsight, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObj)
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}

/**
 * Function to call execute Action API
 * @param {Alert Id} alertIds
 * @returns 
*/
export async function executeActionAPI(alertIds) {

  const requestObj = {
    alertIds: alertIds,
  }
  
  try {
    const response = await fetch(URL.executeAction, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObj),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}