import React from "react";
import { Button, Typography } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link } from "react-router-dom";
import styles from './pageNotFound.module.css';

const PageNotFound = () => {
    return (
        <div className={styles.container}>
            <ErrorOutlineIcon className={styles.icon} />
            <Typography variant="h4" component="h1" className={styles.title}>
                Oops! Page Not Found
            </Typography>
            <Typography variant="body1" >
                The page you are looking for does not exist.
            </Typography>
            <Button
                component={Link}
                to="/"
                variant="contained"
                color="primary"
                className={styles.homeBtn}
            >
                Go to Home
            </Button>
        </div>
    );
};

export default PageNotFound;
