import {
  Box,
  ButtonBase,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useContext, useEffect, useState } from "react";
import { LTCPContext } from "../LTCPContext";
import { useNavigate } from "react-router-dom";
import { getAlertData } from "../api/ltcpBackend";

function AlertRow({
  alert,
  month,
  factory,
  bg,
  subbg,
  brand,
  format,
  capacity,
  demand,
  cu,
}) {
  return (
    <TableRow>
      <TableCell sx={{ fontSize: 12 }}>{alert}</TableCell>
      <TableCell sx={{ fontSize: 12 }}>{month}</TableCell>
      <TableCell sx={{ fontSize: 12 }}>{factory}</TableCell>
      <TableCell sx={{ fontSize: 12 }}>{bg}</TableCell>
      <TableCell sx={{ fontSize: 12 }}>{subbg}</TableCell>
      <TableCell sx={{ fontSize: 12 }}>{brand}</TableCell>
      <TableCell sx={{ fontSize: 12 }}>{format}</TableCell>
      <TableCell sx={{ fontSize: 12 }}>{capacity}</TableCell>
      <TableCell sx={{ fontSize: 12 }}>{demand}</TableCell>
      <TableCell sx={{ fontSize: 12 }}>{cu}%</TableCell>
      <TableCell sx={{ fontSize: 12 }}>
        <Typography
          color="blue"
          fontSize={12}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          View
        </Typography>
      </TableCell>
      <TableCell sx={{ fontSize: 12 }}>
        <Typography
          color="blue"
          fontSize={12}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          View
        </Typography>
      </TableCell>
      <TableCell sx={{ fontSize: 12 }}>
        <Typography
          color="blue"
          fontSize={12}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          View
        </Typography>
      </TableCell>
      <TableCell sx={{ fontSize: 12 }}>
        <Typography
          color="blue"
          fontSize={12}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          View
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function AlertsPage() {
  const navigate = useNavigate();
  const { demandFile, alertFilters } = useContext(LTCPContext);

  const [bgState, setBgState] = useState("");
  const [subcategoryState, setSubcategoryState] = useState("");
  const [brandState, setBrandState] = useState("");
  const [factoryState, setFactoryState] = useState("");
  const [formatState, setFormatState] = useState("");
  const [yearState, setYearState] = useState("");
  const [quarterState, setQuarterState] = useState("");
  const [monthState, setMonthState] = useState("");
  const [result, setResult] = useState({
    alert_count: 0,
    total_demand: 0,
    total_capacity: 0,
    cu: 0,
    data: [],
  });

  async function fetchAlertData() {
    const data = await getAlertData({
      demandFile,
      bgState,
      subcategoryState,
      brandState,
      factoryState,
      formatState,
      yearState,
      quarterState,
      monthState,
    });
    setResult(data);
  }

  useEffect(() => {
    fetchAlertData();
  }, [
    bgState,
    subcategoryState,
    brandState,
    factoryState,
    formatState,
    yearState,
    quarterState,
    monthState,
  ]);

  return (
    <Stack p={4} gap={2} flexGrow={1}>
      <Stack direction="row" gap={2} alignItems="center">
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Typography fontWeight="bold" fontSize={21}>
          Alert Details
        </Typography>
        <Paper sx={{ flexGrow: 1 }}>
          <Stack direction="row" p={1} gap={1}>
            <Stack width="12%">
              <Typography fontSize={14}>BG</Typography>
              <Select
                size="small"
                sx={{ height: 28 }}
                onChange={(e) => setBgState(e.target.value)}
              >
                {alertFilters.bg.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack width="12%">
              <Typography fontSize={14}>Sub-Category</Typography>
              <Select
                size="small"
                sx={{ height: 28 }}
                onChange={(e) => setSubcategoryState(e.target.value)}
              >
                {alertFilters.subcategory.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack width="12%">
              <Typography fontSize={14}>Brand</Typography>
              <Select
                size="small"
                sx={{ height: 28 }}
                onChange={(e) => setBrandState(e.target.value)}
              >
                {alertFilters.brand.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack width="12%">
              <Typography fontSize={14}>Factory</Typography>
              <Select
                size="small"
                sx={{ height: 28 }}
                onChange={(e) => setFactoryState(e.target.value)}
              >
                {alertFilters.factory.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack width="12%">
              <Typography fontSize={14}>Format L2</Typography>
              <Select
                size="small"
                sx={{ height: 28 }}
                onChange={(e) => setFormatState(e.target.value)}
              >
                {alertFilters.format.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack width="12%">
              <Typography fontSize={14}>Year</Typography>
              <Select
                size="small"
                sx={{ height: 28 }}
                onChange={(e) => setYearState(e.target.value)}
              >
                {alertFilters.year.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack width="12%">
              <Typography fontSize={14}>Quarter</Typography>
              <Select
                size="small"
                sx={{ height: 28 }}
                onChange={(e) => setQuarterState(e.target.value)}
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Q1</MenuItem>
                <MenuItem value={2}>Q2</MenuItem>
                <MenuItem value={3}>Q3</MenuItem>
                <MenuItem value={4}>Q4</MenuItem>
              </Select>
            </Stack>
            <Stack width="12%">
              <Typography fontSize={14}>Month</Typography>
              <Select
                size="small"
                sx={{ height: 28 }}
                onChange={(e) => setMonthState(e.target.value)}
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
      <Stack direction="row" gap={1}>
        <Paper
          sx={{
            p: 2,
            width: 220,
            bgcolor: "#C5EAFB",
            border: "1px solid #fff",
          }}
        >
          <Stack>
            <Typography>Count of Alerts</Typography>
            <Typography>20</Typography>
          </Stack>
        </Paper>
        <Paper
          sx={{
            p: 2,
            width: 120,
            bgcolor: "#FFDEDF",
            border: "1px solid #fff",
          }}
        >
          <Stack gap={1}>
            <Typography fontSize={14}>Total Demand</Typography>
            <Stack direction="row" alignItems="end">
              <Typography fontSize={24} lineHeight={1} fontWeight="bold">
                {result.total_demand}
              </Typography>
              <Typography fontSize={14} fontWeight="bold">
                KTPA
              </Typography>
            </Stack>
          </Stack>
        </Paper>
        <Paper
          sx={{
            p: 2,
            width: 120,
            bgcolor: "#FFDEDF",
            border: "1px solid #fff",
          }}
        >
          <Stack gap={1}>
            <Typography fontSize={14}>Total Capacity</Typography>
            <Stack direction="row" alignItems="end">
              <Typography fontSize={24} lineHeight={1} fontWeight="bold">
                {result.total_capacity}
              </Typography>
              <Typography fontSize={14} fontWeight="bold">
                KTPA
              </Typography>
            </Stack>
          </Stack>
        </Paper>
        <Paper
          sx={{
            p: 2,
            width: 120,
            bgcolor: "#FFDEDF",
            border: "1px solid #fff",
          }}
        >
          <Stack gap={1}>
            <Typography fontSize={14}>CU%</Typography>
            <Stack direction="row" alignItems="end">
              <Typography fontSize={24} lineHeight={1} fontWeight="bold">
                {result.cu}
              </Typography>
              <Typography fontSize={14} fontWeight="bold">
                %
              </Typography>
            </Stack>
          </Stack>
        </Paper>
        <Paper sx={{ px: 2, py: 1, gap: 1, flexGrow: 1 }}>
          <Stack>
            <Typography fontSize={14} fontWeight="bold">
              Recommendations:
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              gap={1}
            >
              <Box sx={{ width: 10, height: 10, bgcolor: "#1F36C7" }}></Box>
              <Typography fontSize={14}>Speed/OEE inc</Typography>
              <Box sx={{ width: 10, height: 10, bgcolor: "#01B2FE" }}></Box>
              <Typography fontSize={14}>Pre-build</Typography>
              <Box sx={{ width: 10, height: 10, bgcolor: "#846CE2" }}></Box>
              <Typography fontSize={14}>Cross-sourcing</Typography>
              <Box sx={{ width: 10, height: 10, bgcolor: "#C46CE2" }}></Box>
              <Typography fontSize={14}>Cap Add.</Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
      <Paper
        sx={{
          p: 2,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography fontSize={16} fontWeight="bold">
          Alert Summary
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Alert
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Month
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Factory
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  BG
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Sub BG
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Brand
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Format
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Capacity
                  <br />
                  (Tonnes)
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Demand
                  <br />
                  (Tonnes)
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  CU%
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Prebuild
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Line Speed/
                  <br />
                  OEE Increase
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Cross
                  <br />
                  Sourcing
                </TableCell>
                <TableCell
                  sx={{
                    bgcolor: "#C5EAFB",
                    py: 0,
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  Capex
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result.data.map((item, index) => (
                <AlertRow
                  key={index}
                  alert={item.alert}
                  month={item.month}
                  factory={item.factory}
                  bg={item.bg}
                  subbg={item.subbg}
                  brand={item.brand}
                  format={item.format}
                  capacity={item.capacity}
                  demand={item.demand}
                  cu={item.cu}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
}

export default AlertsPage;
