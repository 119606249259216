import React, { useState, useContext, useEffect, useCallback } from "react";
import { Box, Button, Typography, Drawer } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SelectedAlertsContext } from "../../../utils/SelectedAlertContext";
import InfoTileCard from "./infoTileCard";
import addToActionCartConstants from "../../constants/contants";
import { executeActionAPI } from "../../api/api";
import styles from "./addToActionCart.module.css";
import ActionPopup from "../../components/popup/actionPopup";

const AddToActionCart = ({ open, onClose }) => {
  const { selectedAlerts, setSelectedAlerts, alertDetailsData, setAlertDetailsData } = useContext(SelectedAlertsContext);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    if (open && selectedAlerts.length === 0) {
      onClose();
    }
  }, [open, selectedAlerts, onClose]);

  const executeActionsButtonHandler = useCallback(async () => {
    setPopupOpen(true);
    const alertIds = selectedAlerts.map(alert => alert["Alert Id"]);
    try {
      await executeActionAPI(alertIds);
      const updatedAlerts = alertDetailsData.map((alert) => {
        if (alertIds.includes(alert["Alert Id"])) {
          return {
            ...alert,
            "Alert Status": "Closed",
          };
        }
        return alert;
      });
      setSelectedAlerts([]);
      setAlertDetailsData(updatedAlerts);
      setPopupOpen(false);
    } catch (error) {
      console.error("Error executing actions:", error);
    }
  }, [selectedAlerts, setSelectedAlerts]);


  const handleDialogClose = () => {
    setPopupOpen(false);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} className={styles.drawer}>
      <Box bgcolor="#E1ECFF" padding="1vw" className={styles.drawer}>
        <div className={styles.stickyTitle}>
          <Typography fontWeight="600" variant="body2">
            {`${addToActionCartConstants.actionCartTitleText} (${selectedAlerts.length})`}
          </Typography>
          <div onClick={onClose}>
            <HighlightOffIcon className={styles.closeIcon} />
          </div>
        </div>
      </Box>

      <div className={styles.content}>
        {selectedAlerts.map((infoTileContent) => (
          <InfoTileCard
            key={infoTileContent["Alert Id"]}
            infoTileContent={infoTileContent}
          ></InfoTileCard>
        ))}
      </div>

      <div className={styles.executeActions}>
        <Button className={styles.executeActionsBtn} onClick={executeActionsButtonHandler} >
          <Typography variant="body2" className={styles.btnText}>
            {addToActionCartConstants.executeActionsButtonDisplayText}
          </Typography>
        </Button>
      </div>
      <ActionPopup open={popupOpen} onClose={handleDialogClose} source="addToActionCart" />
    </Drawer>
  );
};

export default AddToActionCart;