import {
  Box,
  Button,
  ButtonBase,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import ltcpRoutes from "../constants/ltcpRoutes";
import { useNavigate } from "react-router-dom";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import TuneIcon from "@mui/icons-material/Tune";
import LaunchIcon from "@mui/icons-material/Launch";
import indiaGeoJSON from "./INDIA_STATES.geo.json";
import DemandCapacityGraph from "../components/DemandCapacityGraph";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LTCPContext } from "../LTCPContext";
import {
  getCuData,
  getDashboardCuData,
  getDashboardCuNestedData,
  getDashboardDemanMap,
} from "LTCP Module/api/ltcpBackend";

function OverviewButton({ label, value, unit, onClick, colorFrom, colorTo }) {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        width: "250px",
        padding: "6px 16px",
        color: "white",
        borderRadius: "3px",
        background: `linear-gradient(to right, ${colorFrom}, ${colorTo})`,
      }}
    >
      <Typography fontSize={14}>{label}</Typography>
      <Stack gap={1} direction="row" alignItems="end">
        <Typography fontSize={20} fontWeight="bold">
          {value}
        </Typography>
        <Typography fontSize={12}>{unit}</Typography>
      </Stack>
      <LaunchIcon
        sx={{ position: "absolute", top: 5, right: 5, fontSize: 10 }}
      />
    </ButtonBase>
  );
}

function NestedTableRow({
  data,
  level,
  bgState,
  subcategoryState,
  branchState,
  brandState,
  formatState,
  factoryState,
  yearState,
  quarterState,
  monthState,
  timeGranularity,
  tableLevel,
}) {
  const { setLoading, demandFile } = useContext(LTCPContext);
  const [nestedData, setNestedData] = useState([]);

  async function fetchData() {
    setLoading(true);

    let payload = {};

    if (level === "national") {
      payload = {
        demandFile,
        bgState,
        subcategoryState,
        branchState,
        brandState: data.brand,
        formatState,
        basepackState: "all",
        factoryState,
        yearState,
        quarterState,
        monthState,
        timeGranularity,
        tableGranularity: tableLevel,
      };
    } else if (level === "branch") {
      payload = {
        demandFile,
        bgState,
        subcategoryState,
        branchState: data.branch,
        brandState,
        formatState,
        basepackState: "all",
        factoryState,
        yearState,
        quarterState,
        monthState,
        timeGranularity,
        tableGranularity: tableLevel,
      };
    } else if (level === "factory") {
      payload = {
        demandFile,
        bgState,
        subcategoryState,
        branchState,
        brandState,
        formatState,
        basepackState: "all",
        factoryState: data.factory,
        yearState,
        quarterState,
        monthState,
        timeGranularity,
        tableGranularity: tableLevel,
      };
    }

    const resp = await getDashboardCuNestedData(payload);

    setNestedData(resp);
    setLoading(false);
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <ButtonBase
            onClick={() =>
              nestedData.length === 0 ? fetchData() : setNestedData([])
            }
          >
            {nestedData.length > 0 ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
            {level === "national" && data.brand}
            {level === "branch" && data.branch}
            {level === "factory" && data.factory}
          </ButtonBase>
        </TableCell>
        {level !== "national" && <TableCell>All</TableCell>}
        <TableCell>All</TableCell>
        <TableCell>{data.demand}</TableCell>
        <TableCell>{data.pack_cap_tpm_new}</TableCell>
        <TableCell>{data.cu_unconst}</TableCell>
        <TableCell>{data.cu_const}</TableCell>
      </TableRow>
      {nestedData.map((item, index) => (
        <TableRow key={index + "_child"}>
          <TableCell></TableCell>
          {level !== "national" && <TableCell>{item.brand}</TableCell>}
          <TableCell>{item.format}</TableCell>
          <TableCell>{item.demand}</TableCell>
          <TableCell>{item.pack_cap_tpm_new}</TableCell>
          <TableCell>{item.cu_unconst}</TableCell>
          <TableCell>{data.cu_const}</TableCell>
        </TableRow>
      ))}
    </>
  );
}

function DashboardPage() {
  const navigate = useNavigate();

  const { cuFilters, setLoading, demandFile } = useContext(LTCPContext);

  const [mapLoaded, setMapLoaded] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [unitToggle, setUnitToggle] = useState("Tonnes");
  const [mapGranularity, setMapGranularity] = useState("Branch");
  const [mapValueToggle, setMapValueToggle] = useState("Demand");
  const [filterDropdown, setFilterDropdown] = useState(null);
  const [bgState, setBgState] = useState("all");
  const [subcategoryState, setSubcategoryState] = useState("all");
  const [branchState, setBranchState] = useState("all");
  const [brandState, setBrandState] = useState("all");
  const [formatState, setFormatState] = useState("all");
  const [factoryState, setFactoryState] = useState("all");
  const [yearState, setYearState] = useState("all");
  const [quarterState, setQuarterState] = useState("all");
  const [monthState, setMonthState] = useState("all");
  const [tableLevel, setTableLevel] = useState("national");
  const [timeGranularity, setTimeGranularity] = useState("MoM");
  const [cuData, setCuData] = useState({
    date: [],
    demand: [],
    pack_cap_tpm: [],
    pack_cap_tpm_new: [],
    cu_const: [],
    cu_unconst: [],
  });
  const [tableData, setTableData] = useState({
    kpi: {
      demand: 0,
      cu_const: 0,
      cu_unconst: 0,
      cap_const: 0,
      cap_unconst: 0,
    },
    location: [],
    data: [],
  });
  const [heatmapData, setHeatmapData] = useState({
    demand: [],
    growth: [],
  });

  function clearFilterHandler() {
    setBgState("all");
    setSubcategoryState("all");
    setBranchState("all");
    setBrandState("all");
    setFormatState("all");
    setFactoryState("all");
  }

  async function fetchDashboardData() {
    setLoading(true);

    const resp = await Promise.all([
      getDashboardCuData({
        demandFile,
        bgState,
        subcategoryState,
        branchState,
        brandState,
        formatState,
        basepackState: "all",
        factoryState,
        yearState,
        quarterState,
        monthState,
        timeGranularity,
        tableGranularity: tableLevel,
      }),
      getCuData({
        demandFile,
        bgState,
        subcategoryState,
        branchState,
        brandState,
        formatState,
        basepackState: "all",
        factoryState,
        yearState,
        quarterState,
        monthState,
        timeGranularity,
      }),
      getDashboardDemanMap({
        demandFile,
        bgState,
        subcategoryState,
        branchState,
        brandState,
        formatState,
        basepackState: "all",
        factoryState,
        yearState,
        quarterState,
        monthState,
        timeGranularity,
        mapGranularity,
      }),
    ]);

    setTableData(resp[0]);
    setCuData(resp[1]);
    setHeatmapData(resp[2]);
    setLoading(false);
  }

  useEffect(() => {
    if (cuFilters.bg.length === 0) return navigate(ltcpRoutes.LANDING);

    fetchDashboardData();
  }, [
    bgState,
    subcategoryState,
    branchState,
    brandState,
    formatState,
    factoryState,
    yearState,
    quarterState,
    monthState,
    timeGranularity,
    mapGranularity,
    tableLevel,
  ]);

  useEffect(() => {
    if (!echarts.getMap("India")) {
      echarts.registerMap("India", indiaGeoJSON, {
        Ladakh: { left: 0, top: 0, width: 32 },
      });
      setMapLoaded(true);
    } else {
      setMapLoaded(true);
    }
  }, []);

  // const coordinatesData = [
  //   {
  //     name: "Mumbai",
  //     value: [72.8777, 19.076],
  //     size: 20,
  //     color: "red",
  //     info: "Financial Capital of India",
  //     details: "Population: 20M",
  //   },
  //   {
  //     name: "Delhi",
  //     value: [77.1025, 28.7041],
  //     size: 15,
  //     color: "blue",
  //     info: "Capital of India",
  //     details: "Population: 30M",
  //   },
  //   {
  //     name: "Bangalore",
  //     value: [77.5946, 12.9716],
  //     size: 25,
  //     color: "green",
  //     info: "IT Hub of India",
  //     details: "Population: 12M",
  //   },
  //   {
  //     name: "Chennai",
  //     value: [80.2707, 13.0827],
  //     size: 18,
  //     color: "purple",
  //     info: "Cultural Capital of India",
  //     details: "Population: 10M",
  //   },
  //   {
  //     name: "Dhatrigram",
  //     value: [88.0371, 23.3857],
  //     size: 10,
  //     color: "hotpink",
  //     info: "Home sweet home",
  //     details: "Nothing",
  //   },
  //   {
  //     name: "Jaipur",
  //     value: [75.78779999999999, 26.9196],
  //     size: 15,
  //     color: "indigo",
  //     info: "Mismatched S3",
  //     details: "13th Dec",
  //   },
  // ];

  // Function to handle circle click
  const handleCircleClick = (params) => {
    if (params.seriesType === "scatter") {
      setPopupData({
        name: params.name,
        info: params.data.info,
        details: params.data.details,
        coordinates: params.value,
      });
    }
  };

  const option = {
    tooltip: {
      trigger: "item",
      borderColor: '#fff',
      borderWidth: 0,
      position: function (point, params, dom, rect, size) {
        const x = point[0] + 10;
        const y = point[1];
        return [x, y];
      },
      formatter: (params) => {
        if (params.seriesType === "scatter") {
          const { name, value, data } = params;
          let color = "black";
          if (data.cu > 90) {
            color = "red";
          }

          return `
            <div style='font-size: 12px; color: black; display: flex; flex-direction: column;'>
              <div style='display: flex; align-items: center;'><p style='width: 80px'>Factory</p>: ${name}</div>
              <div style='display: flex; align-items: center;'><p style='width: 80px'>Demand</p>: ${data.demand} KTPA</div>
              <div style='display: flex; align-items: center;'><p style='width: 80px'>Capacity</p>: ${data.capacity} KTPA</div>
              <div style='display: flex; align-items: center; color: ${color}'><p style='width: 80px'>CU%</p>: ${data.cu}%</div>
            </div>
          `;
        }

        return params.name;
      },
    },
    geo: {
      map: "India",
      roam: false,
      zoom: 1.25,
    },
    visualMap: [
      {
        min: 0,
        max: 100,
        left: "right",
        type: "piecewise",
        itemSymbol: "circle",
        bottom: 0,
        calculable: true,
        pieces: [
          { min: 0, max: 30, color: "#009A6E", label: "<80% CU" },
          { min: 31, max: 60, color: "#E9A400", label: "80-90% CU" },
          { min: 61, max: 100, color: "#FF0027", label: ">90% CU" },
        ],
      },
      {
        min: 0,
        max: 100,
        left: 0,
        type: "piecewise",
        bottom: 0,
        text: ["High", "Low"],
        calculable: true,
        inRange: {
          color: ["#C0C0C0", "#FFE3A0", "#FFCC00", "#FE9900", "#FA6401"],
        },
      },
    ],
    series: [
      {
        tooltip: {
          show: false,
        },
        name: "India",
        type: "map",
        map: "India",
        geoIndex: 0,
        coordinateSystem: "geo",
        itemStyle: {
          borderColor: "white",
        },
        emphasis: {
          label: {
            show: false,
          },
          itemStyle: {
            areaColor: "gold",
          },
        },
        data:
          mapValueToggle === "Demand" ? heatmapData.demand : heatmapData.growth,
      },
      {
        name: "Locations",
        type: "scatter",
        coordinateSystem: "geo",
        data: tableData.location.map((item) => ({
          name: item.name,
          value: [...item.value, item.size],
          itemStyle: {
            color: item.color,
            borderColor: "#333",
            borderWidth: 0.5,
          },
          demand: item.demand,
          capacity: item.capacity,
          cu: item.cu,
        })),
        symbolSize: (value) => value[2],
        emphasis: {
          label: {
            show: false,
            formatter: "{b}",
            color: '#000'
          },
        },
      },
    ],
  };

  // Event handler for chart
  const onEvents = {
    click: handleCircleClick, // Bind click event to the handler
  };

  return (
    <Box p={4} gap={2} flexGrow={1} display="flex" flexDirection="column">
      <Stack direction="row" gap={2} alignItems="center">
        <Typography fontWeight="bold" fontSize={21}>
          Overview
        </Typography>
        <OverviewButton
          label="CU%"
          value={tableData.kpi.cu_const + "%"}
          colorFrom="#EC4887"
          colorTo="#B954A4"
          onClick={() => navigate(ltcpRoutes.CU)}
        />
        <OverviewButton
          label="Demand"
          value={tableData.kpi.demand}
          unit={"KTPA"}
          colorFrom="#855FC0"
          colorTo="#5346BA"
          onClick={() => navigate(ltcpRoutes.DEMAND)}
        />
        <OverviewButton
          label="Capacity"
          value={tableData.kpi.cap_const}
          unit={unitToggle === "MU" ? "MI Units" : "KTPA"}
          colorFrom="#46C5F2"
          colorTo="#6692D9"
          // onClick={() => navigate(ltcpRoutes.CAPACITY)}
          onClick={() => {}}
        />
        <Stack
          direction="row"
          bgcolor="#FFDEDF"
          justifyContent="space-between"
          alignItems="center"
          width={180}
          px={2}
        >
          <Typography fontSize={14}>No of Alerts</Typography>
          <Typography fontSize={30} fontWeight="bold">
            0
          </Typography>
        </Stack>
      </Stack>
      <Paper>
        <Stack
          px={2}
          direction="row"
          bgcolor="white"
          borderRadius="3px"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Stack px={3} gap={2} py={1} direction="row">
            <Box display="flex" flexDirection="row" alignItems="center">
              <ButtonBase onClick={(e) => setFilterDropdown(e.currentTarget)}>
                <TuneIcon fontSize="small" />
                <Typography ml={1}>Filter</Typography>
                <KeyboardArrowDownIcon fontSize="small" />
              </ButtonBase>
              <Menu
                open={Boolean(filterDropdown)}
                anchorEl={filterDropdown}
                onClose={() => setFilterDropdown(null)}
              >
                <Stack p={1} gap={1} width={150}>
                  <Stack>
                    <Typography fontSize={12}>BG</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={bgState}
                      onChange={(e) => setBgState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.bg.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12}>Sub-Category</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={subcategoryState}
                      onChange={(e) => setSubcategoryState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.subcategory.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12}>Branch</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={branchState}
                      onChange={(e) => setBranchState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.branch.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  {/* <Stack>
                    <Typography fontSize={12}>Cluster</Typography>
                    <Select size="small" sx={{ height: 28 }}>
                      {cuFilters.cluster.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack> */}
                  <Stack>
                    <Typography fontSize={12}>Brand</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={brandState}
                      onChange={(e) => setBrandState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.brand.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12}>Format L2</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={formatState}
                      onChange={(e) => setFormatState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.format.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12}>Factory</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={factoryState}
                      onChange={(e) => setFactoryState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.factory.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack direction="row" justifyContent="end">
                    <Button variant="text" onClick={clearFilterHandler}>
                      Clear
                    </Button>
                    {/* <Button
                      size="small"
                      variant="contained"
                      onClick={() => setFilterDropdown(null)}
                    >
                      Apply
                    </Button> */}
                  </Stack>
                </Stack>
              </Menu>
            </Box>
            <Box gap={1} display="flex" flexDirection="row" alignItems="center">
              <Typography>Year</Typography>
              <Select
                size="small"
                sx={{ width: 100, height: 28 }}
                value={yearState}
                onChange={(e) => setYearState(e.target.value)}
              >
                <MenuItem value="all">All</MenuItem>
                {cuFilters.year.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box gap={1} display="flex" flexDirection="row" alignItems="center">
              <Typography>Quarter</Typography>
              <Select
                size="small"
                sx={{ width: 100, height: 28 }}
                value={quarterState}
                onChange={(e) => setQuarterState(e.target.value)}
                disabled={timeGranularity === "MoM"}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="Q1">Q1</MenuItem>
                <MenuItem value="Q2">Q2</MenuItem>
                <MenuItem value="Q3">Q3</MenuItem>
                <MenuItem value="Q4">Q4</MenuItem>
              </Select>
            </Box>
            <Box gap={1} display="flex" flexDirection="row" alignItems="center">
              <Typography>Month</Typography>
              <Select
                size="small"
                sx={{ width: 100, height: 28 }}
                value={monthState}
                onChange={(e) => setMonthState(e.target.value)}
                disabled={timeGranularity === "QoQ"}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="01">Jan</MenuItem>
                <MenuItem value="02">Feb</MenuItem>
                <MenuItem value="03">Mar</MenuItem>
                <MenuItem value="04">Apr</MenuItem>
                <MenuItem value="05">May</MenuItem>
                <MenuItem value="06">Jun</MenuItem>
                <MenuItem value="07">Jul</MenuItem>
                <MenuItem value="08">Aug</MenuItem>
                <MenuItem value="09">Sep</MenuItem>
                <MenuItem value="10">Oct</MenuItem>
                <MenuItem value="11">Nov</MenuItem>
                <MenuItem value="12">Dec</MenuItem>
              </Select>
            </Box>
          </Stack>
          <ToggleButtonGroup
            exclusive
            value={unitToggle}
            onChange={(_, v) => setUnitToggle(v)}
          >
            <ToggleButton value="Tonnes">Tonnes</ToggleButton>
            <ToggleButton value="MU">MU</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Paper>
      <Stack
        gap={2}
        flexGrow={1}
        direction="row"
        height="calc(100vh - 236px)"
        maxHeight="calc(100vh - 236px)"
      >
        <Paper
          sx={{
            p: 1,
            flexGrow: 3,
            display: "flex",
            flexDirection: "column",
            minWidth: 460,
            maxWidth: 460,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize={16} fontWeight="bold">
              Region Wise Demand
            </Typography>
            <Stack gap={1}>
              <ToggleButtonGroup
                exclusive
                size="small"
                value={mapGranularity}
                onChange={(_, v) => setMapGranularity(v)}
              >
                <ToggleButton value="Branch">Branch</ToggleButton>
                <ToggleButton value="Cluster">Cluster</ToggleButton>
              </ToggleButtonGroup>
              <ToggleButtonGroup
                exclusive
                size="small"
                value={mapValueToggle}
                onChange={(_, v) => setMapValueToggle(v)}
              >
                <ToggleButton value="Demand">Demand</ToggleButton>
                <ToggleButton value="Growth%">Growth%</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Stack>
          <ReactECharts
            option={mapLoaded ? option : {}}
            onEvents={onEvents}
            style={{ height: "100%", width: "100%" }}
          />
        </Paper>
        <Stack gap={2} flexGrow={7}>
          <Paper
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              height: "43%",
              maxHeight: "180px",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize={16} fontWeight="bold">
                CU%
              </Typography>
              <ToggleButtonGroup
                exclusive
                value={timeGranularity}
                onChange={(_, v) => setTimeGranularity(v)}
              >
                <ToggleButton value="QoQ">QoQ</ToggleButton>
                <ToggleButton value="MoM">MoM</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <DemandCapacityGraph
              labels={cuData.date}
              demand={cuData.demand}
              capacity={cuData.pack_cap_tpm_new}
              cu={cuData.cu_const.map((item) => Number.parseInt(item))}
            />
          </Paper>
          <Paper
            sx={{
              p: 1,
              gap: 1,
              minHeight: "50%",
              maxHeight: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              overflowY: "auto",
            }}
          >
            <Stack direction="row" justifyContent="end">
              <ToggleButtonGroup
                exclusive
                value={tableLevel}
                onChange={(_, v) => setTableLevel(v)}
              >
                <ToggleButton value="national">National</ToggleButton>
                <ToggleButton value="branch">Branch</ToggleButton>
                <ToggleButton value="factory">Factory</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <TableContainer>
              <Table
                size="small"
                stickyHeader
                sx={{ height: "100%", maxHeight: "100%", overflowY: "auto" }}
              >
                <TableHead>
                  <TableRow>
                    {tableLevel === "factory" && (
                      <TableCell sx={{ bgcolor: "#C5EAFB" }}>Factory</TableCell>
                    )}
                    {tableLevel === "branch" && (
                      <TableCell sx={{ bgcolor: "#C5EAFB" }}>Branch</TableCell>
                    )}
                    <TableCell sx={{ bgcolor: "#C5EAFB" }}>Brand</TableCell>
                    <TableCell sx={{ bgcolor: "#C5EAFB" }}>FormatL2</TableCell>
                    <TableCell sx={{ bgcolor: "#C5EAFB" }}>
                      Demand (T)
                    </TableCell>
                    <TableCell sx={{ bgcolor: "#C5EAFB" }}>
                      Capacity (T)
                    </TableCell>
                    <TableCell sx={{ bgcolor: "#C5EAFB" }}>
                      Pack Unc. CU%
                    </TableCell>
                    <TableCell sx={{ bgcolor: "#C5EAFB" }}>
                      Pack Con. CU%
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.data.map((item, index) => (
                    <NestedTableRow
                      key={index}
                      data={item}
                      level={tableLevel}
                      bgState={bgState}
                      subcategoryState={subcategoryState}
                      branchState={branchState}
                      brandState={brandState}
                      formatState={formatState}
                      factoryState={factoryState}
                      yearState={yearState}
                      quarterState={quarterState}
                      monthState={monthState}
                      timeGranularity={timeGranularity}
                      tableLevel={tableLevel}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
}

export default DashboardPage;
