import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import CustomCard from "./CustomCard";
import styles from "./main.module.css";
import samarthImg from "./../../assets/images/scnc_card.jpg";
import ltcpImg from "./../../assets/images/cp_card.jpg";

const MainLanding = () => {
  const location = useLocation();

  const cardsData = [
    {
      title: "Samarth",
      description:
        "A use case driven capabilities along with value centricity and robust data/ tech strategy to anticipate and act on supply chain disruptions in order to deliver SCNC value goals - SecCCF, DOH & NMSCC.",
      image: samarthImg,
      click: "/samarth",
    },
    {
      title: "Capacity Planning",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      image: ltcpImg,
      click: "/ltcp/landing",
    },
  ];

  return (
    <div className={styles.container}>
      {/* Render cards only if the current path is "/" */}
      {location.pathname === "/" && (
        <div className={styles.cardWrapper}>
          {cardsData.map((card, index) => (
            <CustomCard
              key={index}
              title={card.title}
              description={card.description}
              image={card.image}
              click={card.click}
            />
          ))}
        </div>
      )}
      {/* Render nested routes */}
      <Outlet />
    </div>
  );
};

export default MainLanding;
