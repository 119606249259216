import React, { createContext, useState } from "react";

export const SelectedAlertsContext = createContext();

export const SelectedAlertsProvider = ({ children }) => {
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [alertDetailsData, setAlertDetailsData] = useState([]);

  return (
    <SelectedAlertsContext.Provider value={{ selectedAlerts, setSelectedAlerts, filteredData, setFilteredData, alertDetailsData, setAlertDetailsData}}>
      {children}
    </SelectedAlertsContext.Provider>
  );
};