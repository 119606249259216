import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "../src/components/header/header";
import MainLanding from "../src/components/main/main";
import SamarthRouterHandler from "../src/Samarth Module/routers/router";
import Landing from "Samarth Module/pages/landing/landing";
import AlertDetails from "Samarth Module/pages/alertDeatils1/alertDeatils";
import PageNotFound from "Samarth Module/pages/pageNotFound/pageNotFound";
import LTCPRouter from "../src/LTCP Module/routers/router";
import HeaderLayout from "Samarth Module/components/headerLayout/headerLayout";

const MainRouterHandler = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLanding />} />
        <Route path="/ltcp/*" element={<LTCPRouter />} />
        {/* <Route path="/" element={<MainLanding />} /> */}
        <Route path="/samarth/*" element={<SamarthRouterHandler />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouterHandler;
