import React from "react";
import { useNavigate } from "react-router-dom";
import { Link, Breadcrumbs, Stack } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import styles from "./routesHistory.module.css";

const RoutesHistory = (props) => {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <ArrowCircleLeftOutlinedIcon
        className={styles.colorGray}
        onClick={() => handleNavigation(props.content.prev_route)}
      ></ArrowCircleLeftOutlinedIcon>

      <Breadcrumbs sx={{
        fontSize: '14px',
        "& .MuiBreadcrumbs-root": { fontSize: "14px", display: "flex", justifyContent: 'center' }
      }}
        separator={<NavigateNextIcon className={styles.colorGray} />}
      >
        {props.content.other_routes.map((routeContent, index) => {
          return routeContent.route_present === "yes" ? (
            <Link
              key={index}
              onClick={() => handleNavigation(routeContent.route)}
              className={styles.linkText}>
              {routeContent.name}
            </Link>
          ) : (
            <span key={index} className={styles.routeContentText}>
              {routeContent.name}
            </span>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
};

export default RoutesHistory;
