import React, { useState, useContext, useEffect, useCallback } from "react";
import { Button, Checkbox } from "@mui/material";
import { ThumbUp } from "@mui/icons-material";
import { SelectedAlertsContext } from "../../../../utils/SelectedAlertContext";
import alertDetailsConstants from "../../../constants/contants";
import styles from "./resolve.module.css";
import DemandPopup from "../../../components/popup/demandPopup";
import ActionPopup from "../../../components/popup/actionPopup";
import { executeActionAPI } from "../../../api/api";
import { format } from "date-fns";

const Resolve = ({ alert, onCardSelect }) => {
  const { selectedAlerts, setSelectedAlerts, alertDetailsData, setAlertDetailsData } = useContext(SelectedAlertsContext);
  const [selectedCards, setSelectedCards] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [simulateDialogOpen, setSimulateDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [resolveData, setResolveData] = useState([]);

  useEffect(() => {
    setResolveData(alert?.resolveData || []);
  }, [alert?.resolveData]);

  useEffect(() => {
    const currentSelected = selectedAlerts.find(
      (selectedAlert) => selectedAlert["Alert Id"] === alert["Alert Id"]
    );
    setSelectedCards(currentSelected ? currentSelected.selectedCard : []);
  }, [selectedAlerts, alert["Alert Id"]]);

  const handleCheckboxChange = (card) => {
    setSelectedCards((prevSelected) => {
      const isSelected = prevSelected.some((c) => c.Title === card.Title);
      const updatedSelection = isSelected
        ? prevSelected.filter((c) => c.Title !== card.Title)
        : [...prevSelected, card];

      onCardSelect(updatedSelection);

      if (updatedSelection.length === 0) {
        setSelectedAlerts((prevAlerts) =>
          prevAlerts.filter((selectedAlert) => selectedAlert["Alert Id"] !== alert["Alert Id"])
        );
      }

      return updatedSelection;
    });
  };

  const calculateCumulativeQuantity = useCallback(() => {
    return selectedCards.reduce((sum, card) => {
      const quantityAtRisk = card.data.find((d) => d.label === "Quantity At Risk Recovered")?.value || 0;
      return sum + Number(quantityAtRisk);
    }, 0);
  }, [selectedCards]);

  const isCardDisabled = useCallback(
    (card) => {
      if (alert["Alert Status"] === "Closed") return true;

      const cumulativeQuantity = calculateCumulativeQuantity();
      const isCardSelected = selectedCards.some((selected) => selected.Title === card.Title);
      if (isCardSelected) return false;

      const remainingCapacity = Number(alert["Quantity at Risk"]) - cumulativeQuantity;
      return remainingCapacity <= 0;
    },
    [alert, calculateCumulativeQuantity, selectedCards]
  );

  const handleExecuteActionClick = () => {
    setDialogTitle(selectedCards.map((card) => card.Title).join(", "));
    setDialogOpen(true);
  };

  const excuteAction = async () => {
    try {
      await executeActionAPI([alert["Alert Id"]]);
      const updatedAlerts = alertDetailsData.map((item) =>
        item["Alert Id"] === alert["Alert Id"]
          ? { ...item, "Alert Status": "Closed" }
          : item
      );
      setAlertDetailsData(updatedAlerts);
        
      const updatedSelectedAlerts = selectedAlerts.filter(
        (item) => item["Alert Id"] !== alert["Alert Id"]
      );
      setSelectedAlerts(updatedSelectedAlerts);
    
    } catch (error) {
      console.error("Error executing actions:", error);
    }
  }

  const handleDialogClose = () => {
    excuteAction();
    setDialogOpen(false);
  };
  const handleSimulateDialogClose = () => setSimulateDialogOpen(false);

  const renderCardBody = (card) => {
    return card.data.map((d, index) => (
      !d.label.includes("Base Unit of Measure") && (
        <div key={index} className={styles.cardContain}>
          <span className={styles.label}>{d.label}</span>
          <span className={styles.value}>
            {d.value !== null &&
              (d.label === "Quantity At Risk" || d.label === "Quantity At Risk Recovered"
                ? Math.min(Number(d.value) || 0, Number(alert["Quantity at Risk"]) || 0).toFixed(0)
                : d.label.includes("Date")
                ? format(new Date(d.value), "dd-MMM-yy")
                : d.value)}

            {d.value !== null && d.label.includes("Cost") && <span>L</span>}
            {d.value !== null && d.label.includes("Quantity") && (
              <span>{card.data.find((item) => item.label === "Base Unit of Measure")?.value}</span>
            )}
            {d.value !== null && d.label.includes("Percentage") && <span>%</span>}
          </span>
        </div>
      )
    ));
  };

  return (
    <div className={styles.resolveContainer}>
      <div className={styles.resolveTitle}>
        <span className={styles.title}>Intelligent Recommendations</span>
        <Button
          disabled={!selectedCards.length}
          className={styles.actionBtn}
          onClick={handleExecuteActionClick}
        >
          {alertDetailsConstants.executeActionsButtonDisplayText}
        </Button>
      </div>
      <div className={styles.resolveBody}>
        {resolveData.map((card, index) => (
          <div
            key={index}
            className={`${styles.card} ${isCardDisabled(card) ? styles.disabledCard : ""}`}
          >
            <div className={styles.cardHeader}>
              {card.Title}
              <span className={styles.cardIcons}>
                {card.isRecommendated && <ThumbUp className={styles.thumbIcon} />}
                {!card.Title.includes("Current") && (
                  <Checkbox
                    disableRipple
                    sx={{
                      padding: 0,
                      height: "16px",
                      width: "16px",
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    onChange={() => handleCheckboxChange(card)}
                    checked={selectedCards.some((c) => c.Title === card.Title)}
                    disabled={isCardDisabled(card)}
                  />
                )}
              </span>
            </div>
            <div className={styles.cardBody}>{renderCardBody(card)}</div>
          </div>
        ))}
      </div>
      <DemandPopup open={simulateDialogOpen} handleClose={handleSimulateDialogClose} />
      <ActionPopup open={dialogOpen} onClose={handleDialogClose} title={dialogTitle} source="resolve" />
    </div>
  );
};

export default Resolve;
