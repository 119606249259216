import React, { useEffect, useState } from "react";
import { RiHome2Line } from "react-icons/ri";
// import { IoNotificationsOutline } from "react-icons/io5";
import { RiLogoutBoxRLine } from "react-icons/ri";
import styles from "./header.module.css";
import headerConstants from "../../Samarth Module/constants/contants";
import { useMsal } from '@azure/msal-react';
import { Avatar} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { accounts, instance } = useMsal();
  const activeAccount = accounts[0];
  const [userRole, setUserRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const navigate = useNavigate();

  // const handleLogout = () => {
  //   instance.logoutRedirect().catch(e => {
  //     console.error(e);
  //   });
  // };

  // useEffect(() => {
  //   if(accounts.length > 0) {
  //     if(activeAccount.idTokenClaims && activeAccount.idTokenClaims.roles && activeAccount.idTokenClaims.roles.length > 0) {
  //       setUserRole(activeAccount.idTokenClaims.roles[0]);
  //     } else {
  //       setUserRole('');
  //     }
  //     getName();
  //   }

  // },[accounts, instance, activeAccount.idTokenClaims]);

  // const getName = () => {
  //   const nameParts = activeAccount.name?.split(', ') || [];
  //     if (nameParts.length > 1) {
  //       setFirstName(nameParts[1]);
  //     } else {
  //       setFirstName(activeAccount.name || '')
  //     }
  // };

  // const getInitials = () => {
  //   const splitName = activeAccount.name.split(' ');
  //   return splitName.map(part => part[0]).join('').toUpperCase();
  // };

  return (
    <div className={styles.header}>
      <div className={styles.headerStart}>
        <RiHome2Line className={styles.headerHomeButton}  onClick={() => navigate('/')}/>
        <img
          alt={headerConstants.headerHulLogo.name}
          src={headerConstants.headerHulLogo.path}
          className={styles.ulImg}
          onClick={() => navigate('/')}
        />
        {/* <img
          alt={headerConstants.samarthHulLogo.name}
          src={headerConstants.samarthHulLogo.path}
          className={styles.ulImg}
          style={{ "height": "42px", width: "42px"}}
        /> */}
      </div>
      <span className={styles.headerTitle}>{headerConstants.headerTitle}</span>
      {/* <div className={styles.headerEnd}>
        <div className={styles.headerUserProfileSection}>
          <Avatar className={styles.avatar}>{getInitials()}</Avatar>
          <span className={styles.zeroMarginParagraph}>
            <span style={{ fontWeight: 500}}>{headerConstants.headerUserProfileGreetingMessage} {firstName}</span>,<br />{userRole}
          </span>
        </div>
        <RiLogoutBoxRLine className={styles.headerLogoutButton} onClick={handleLogout} />
      </div> */}
    </div>
  );
};

export default Header;
