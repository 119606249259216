import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "../pages/landing/landing";
import AlertDetails from "../pages/alertDeatils1/alertDeatils";
import PageNotFound from "../pages/pageNotFound/pageNotFound";
import Header from "../../components/header/header";
import DailyPhasing from "Samarth Module/pages/dailyPhasing/dailyPhasing";

const SamarthRouterHandler = () => {
  return (
    <>
    <Header />
      {/* <Header /> */}
      {/* <HeaderLayout /> */}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="alert_details" element={<AlertDetails />} />
        <Route path="daily_phasing" element={<DailyPhasing />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default SamarthRouterHandler;