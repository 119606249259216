import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const CustomCard = ({ title, description, image, click }) => {
  const navigate = useNavigate();
  
  return (
    <Card sx={{ maxWidth: 400 }}>
      <CardMedia sx={{ height: 300 }} image={image} title={title} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" sx={{ backgroundColor:"#1565c0", color: "white"}} onClick={() => navigate(click)}>View More</Button>
      </CardActions>
    </Card>
  );
};

export default CustomCard;
