import { createContext, useState } from "react";


const CU_FILTER_DEFAULT_VALUES = {
  bg: [],
  subcategory: [],
  branch: [],
  brand: [],
  format: [],
  basepack: [],
  factory: [],
  year: [],
  quarter: [],
  month: [],
};

const DEMAND_FILTER_DEFAULT_VALUES = {
  bg: [],
  subcategory: [],
  branch: [],
  brand: [],
  format: [],
  basepack: [],
  factory: [],
  year: [],
  quarter: [],
  month: [],
};

const CAPACITY_FILTER_DEFAULT_VALUES = {
  bg: [],
  subcategory: [],
  branch: [],
  brand: [],
  format: [],
  basepack: [],
  factory: [],
  year: [],
  quarter: [],
  month: [],
};

const ALERT_FILTER_DEFAULT_VALUES = {
  bg: [],
  subcategory: [],
  brand: [],
  factory: [],
  format: [],
  year: [],
  quarter: [],
  month: [],
};

const LTCPContext = createContext(null);

function LTCPContextProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [demandFile, setDemandFile] = useState("v1");
  const [cuFilters, setCuFilters] = useState(CU_FILTER_DEFAULT_VALUES);
  const [demandFilters, setDemandFilters] = useState(
    DEMAND_FILTER_DEFAULT_VALUES
  );
  const [capacityFilters, setCapacityFilters] = useState(
    CAPACITY_FILTER_DEFAULT_VALUES
  );
  const [alertFilters, setAlertFilters] = useState(ALERT_FILTER_DEFAULT_VALUES);

  return (
    <LTCPContext.Provider
      value={{
        loading,
        setLoading,
        alertFilters,
        setAlertFilters,
        demandFile,
        setDemandFile,
        cuFilters,
        setCuFilters,
        demandFilters,
        setDemandFilters,
        capacityFilters,
        setCapacityFilters,
      }}
    >
      {children}
    </LTCPContext.Provider>
  );
}

export { LTCPContext, LTCPContextProvider };
