import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Select,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import { getUploadInfo, uploadFile } from "../api/ltcpBackend";
import { LTCPContext } from "../LTCPContext";

const TILE_VALUE = { name: "", time: "" };

const VisuallyHiddenInput = styled("input")({
  left: 0,
  bottom: 0,
  width: 1,
  height: 1,
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
});

function UploadTile({ filename, document, name, time, onDownload }) {
  const { setLoading } = useContext(LTCPContext);

  async function uploadFileApi(filename, file) {
    setLoading(true);
    await uploadFile(filename, file);
    setLoading(false);
  }

  return (
    <Paper>
      <Stack direction="row">
        <Box px={2} py={1} width="20%" sx={{ borderRight: "1px solid #eee" }}>
          <Typography>{document}</Typography>
        </Box>
        <Box px={2} py={1} width="20%" sx={{ borderRight: "1px solid #eee" }}>
          <Button
            component="label"
            role={undefined}
            variant="text"
            tabIndex={-1}
            startIcon={<UploadIcon />}
          >
            Upload files
            <VisuallyHiddenInput
              type="file"
              onChange={(e) =>
                e.target.files.length > 0 &&
                uploadFileApi(filename, e.target.files[0])
              }
              multiple
            />
          </Button>
        </Box>
        <Box px={2} py={1} width="20%" sx={{ borderRight: "1px solid #eee" }}>
          <Button
            component="label"
            role={undefined}
            variant="text"
            tabIndex={-1}
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            Download files
          </Button>
        </Box>
        <Box px={2} py={1} width="20%" sx={{ borderRight: "1px solid #eee" }}>
          <Typography>{name}</Typography>
        </Box>
        <Box px={2} py={1} width="20%">
          <Typography>{time}</Typography>
        </Box>
      </Stack>
    </Paper>
  );
}

function UploadPage() {
  const { setLoading } = useContext(LTCPContext);

  const [manualDemand1Tile, setManualDemand1Tile] = useState(TILE_VALUE);
  const [manualDemand2Tile, setManualDemand2Tile] = useState(TILE_VALUE);
  const [sourcingMatrix, setSourcingMatrix] = useState(TILE_VALUE);
  const [capacityMasterTile, setCapacityMasterTile] = useState(TILE_VALUE);
  const [productMasterTile, setProductMasterTile] = useState(TILE_VALUE);
  const [depotMasterTile, setDepotMasterTile] = useState(TILE_VALUE);
  const [factoryMasterTile, setFactoryMasterTile] = useState(TILE_VALUE);
  const [makeShiftMasterTile, setMakeShiftMasterTile] = useState(TILE_VALUE);
  const [packShiftMasterTile, setPackShiftMasterTile] = useState(TILE_VALUE);

  async function fetchUploadInfo() {
    setLoading(true);

    const data = await getUploadInfo();

    setManualDemand1Tile(data.manual_demand_1);
    setManualDemand2Tile(data.manual_demand_2);
    setSourcingMatrix(data.sourcing_matrix);
    setCapacityMasterTile(data.capacity_master);
    setProductMasterTile(data.product_master);
    setDepotMasterTile(data.depot_master);
    setFactoryMasterTile(data.factory_master);
    setMakeShiftMasterTile(data.make_shift_master);
    setPackShiftMasterTile(data.pack_shift_master);

    setLoading(false);
  }

  useEffect(() => {
    fetchUploadInfo();
  }, []);

  return (
    <Stack p={4} gap={3}>
      <Typography fontWeight="bold" fontSize={21}>
        Upload Log
      </Typography>
      <Stack direction="row" gap={3}>
        <Box gap={1} display="flex" alignItems="center">
          <Typography>BG</Typography>
          <Select
            size="small"
            sx={{ px: 2, py: 0, width: 180, fontSize: 12, bgcolor: "#fff" }}
          ></Select>
        </Box>
        <Box gap={1} display="flex" alignItems="center">
          <Typography>Sub-Category</Typography>
          <Select
            size="small"
            sx={{ px: 2, py: 0, width: 180, fontSize: 12, bgcolor: "#fff" }}
          ></Select>
        </Box>
      </Stack>
      <Stack spacing={2}>
        <UploadTile
          filename="manual_demand_1"
          document="Manual Demand 1"
          name={manualDemand1Tile.name}
          time={manualDemand1Tile.time}
        />
        <UploadTile
          filename="manual_demand_2"
          document="Manual Demand 2"
          name={manualDemand2Tile.name}
          time={manualDemand2Tile.time}
        />
        <UploadTile
          filename="sourcing_matrix"
          document="Sourcing Matrix"
          name={sourcingMatrix.name}
          time={sourcingMatrix.time}
        />
        <UploadTile
          filename="capacity_master"
          document="Capacity Master"
          name={capacityMasterTile.name}
          time={capacityMasterTile.time}
        />
        <UploadTile
          filename="product_master"
          document="Product Master"
          name={productMasterTile.name}
          time={productMasterTile.time}
        />
        <UploadTile
          filename="depot_master"
          document="Depot Master"
          name={depotMasterTile.name}
          time={depotMasterTile.time}
        />
        <UploadTile
          filename="factory_master"
          document="Factory Master"
          name={factoryMasterTile.name}
          time={factoryMasterTile.time}
        />
        <UploadTile
          filename="make_shift_master"
          document="Make-Shift Master"
          name={makeShiftMasterTile.name}
          time={makeShiftMasterTile.time}
        />
        <UploadTile
          filename="pack_shift_master"
          document="Pack-Shift Master"
          name={packShiftMasterTile.name}
          time={packShiftMasterTile.time}
        />
      </Stack>
      <Typography fontSize={12} color="#333">
        Note: Minimum selection of 1 BG and 1 Sub BG while uploading file. While
        downloading it will download the selected BG and Sub BG
      </Typography>
    </Stack>
  );
}

export default UploadPage;
