import ReactECharts from "echarts-for-react";

function DemandCapacityGraph({ demand, capacity, cu, labels }) {
  const option = {
    grid: {
      top: 20,
      left: 70,
      right: 70,
      bottom: 50,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    legend: {
      data: ["Demand", "Capacity", "CU%"],
      top: "bottom",
    },

    xAxis: [
      {
        type: "category",
        data: labels,
        axisPointer: {
          type: "shadow",
        },
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        splitLine: {
          show: false,
        },
      },
      {
        type: "value",
        axisLabel: {
          formatter: "{value}%",
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: "Demand",
        type: "bar",
        data: demand,
        itemStyle: {
          color: "#01B2FE",
        },
        barGap: "0",
        barCategoryGap: "40%",
      },
      {
        name: "Capacity",
        type: "bar",
        data: capacity,
        itemStyle: {
          color: "#1F36C7",
        },
      },
      {
        name: "CU%",
        type: "line",
        yAxisIndex: 1,
        symbol: "circle",
        tooltip: {
          valueFormatter: function (value) {
            return value + "%";
          },
        },
        data: cu,
        itemStyle: {
          color: "#FFBD65",
        },
        label: {
          show: true,
          position: "top",
          formatter: "{c}%",
        },
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ height: "100%", width: "100%" }} />
  );
}

export default DemandCapacityGraph;
