import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material"
import InfoPaneLeft from "./components/infoPaneLeft";
import ExceptionPaneTop from "./components/exceptionPaneTop";
import ValueChainPaneRight from "./components/valueChainPaneRight";
import landingConstants from "../../constants/landing";
import styles from "./landing.module.css";
import { landingAPI } from "../../api/api";
import Snackbar from "../../components/snackbar/snackbar";

const Landing = () => {
  const [landingData, setLandingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await landingAPI();
        setLandingData(data[0]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching landing data:", error);
        setLoading(false);
        setSnackbarMessage("Failed to fetch landing data. Please try again later.");
        setSnackbarOpen(true);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={styles.landing}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={styles.landingContainer}>
            <InfoPaneLeft info={landingData.data} />
            <div className={styles.rightSection}>
              <ExceptionPaneTop
                info={landingData.data}
                criticalityGraph={landingData.exceptionByCriticality}
                entityType={landingData.exceptionByType}
              />
            </div>
          </div>
          <div className={styles.valueChainPaneRight}>
            <div className={styles.imageContainer}>
              <img alt={landingConstants.e2eValueChain.image.name} src={landingConstants.e2eValueChain.image.path} className={styles.landingImg} />
              <div className={styles.textOverlay}>
                <p>{landingConstants.e2eValueChainText}</p>
              </div>
            </div>
            <ValueChainPaneRight value={landingData.stream} />
          </div>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Landing;
