// const BASE_URL = "http://localhost:8000/api";
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL + "/api";

export async function getCuFilters() {
  const resp = await fetch(BASE_URL + "/cu_filters");
  const data = await resp.json();

  return data;
}

export async function getDemandFilters() {
  const resp = await fetch(BASE_URL + "/demand_filters");
  const data = await resp.json();

  return data;
}

export async function getCapacityFilters() {
  const resp = await fetch(BASE_URL + "/capacity_filters");
  const data = await resp.json();

  return data;
}

export async function getAlertFilters() {
  const resp = await fetch(BASE_URL + "/alert_filters");
  const data = await resp.json();

  return data;
}

export async function getDashboardCuData({
  demandFile,
  bgState,
  subcategoryState,
  branchState,
  brandState,
  formatState,
  basepackState,
  factoryState,
  yearState,
  quarterState,
  monthState,
  timeGranularity,
  tableGranularity,
}) {
  const resp = await fetch(
    BASE_URL +
      "/dashboard_cu_data?" +
      new URLSearchParams({
        demand_file: demandFile,
        bg: bgState,
        subcategory: subcategoryState,
        branch: branchState,
        brand: brandState,
        format: formatState,
        basepack: basepackState,
        factory: factoryState,
        year: yearState,
        quarter: quarterState,
        month: monthState,
        time_gran: timeGranularity,
        table_gran: tableGranularity,
      })
  );

  const data = await resp.json();

  return data;
}

export async function getDashboardCuNestedData({
  demandFile,
  bgState,
  subcategoryState,
  branchState,
  brandState,
  formatState,
  basepackState,
  factoryState,
  yearState,
  quarterState,
  monthState,
  timeGranularity,
  tableGranularity,
}) {
  const resp = await fetch(
    BASE_URL +
      "/dashboard_cu_nested_data?" +
      new URLSearchParams({
        demand_file: demandFile,
        bg: bgState,
        subcategory: subcategoryState,
        branch: branchState,
        brand: brandState,
        format: formatState,
        basepack: basepackState,
        factory: factoryState,
        year: yearState,
        quarter: quarterState,
        month: monthState,
        time_gran: timeGranularity,
        table_gran: tableGranularity,
      })
  );

  const data = await resp.json();

  return data;
}

export async function getDashboardDemanMap({
  demandFile,
  bgState,
  subcategoryState,
  branchState,
  brandState,
  formatState,
  basepackState,
  factoryState,
  yearState,
  quarterState,
  monthState,
  timeGranularity,
  mapGranularity,
}) {
  const resp = await fetch(
    BASE_URL +
      "/dashboard_demand_map?" +
      new URLSearchParams({
        demand_file: demandFile,
        bg: bgState,
        subcategory: subcategoryState,
        brnach: branchState,
        brand: brandState,
        format: formatState,
        basepack: basepackState,
        factory: factoryState,
        year: yearState,
        quarter: quarterState,
        month: monthState,
        time_gran: timeGranularity,
        map_gran: mapGranularity,
      })
  );

  const data = await resp.json();

  return data
}

export async function getCapacityData({
  bgState,
  subcategoryState,
  factoryState,
  formatState,
  basepackState,
  cascadeState,
  lineState,
  yearState,
  monthState,
}) {
  const resp = await fetch(
    BASE_URL +
      "/capacity_data?" +
      new URLSearchParams({
        bg: bgState,
        subcategory: subcategoryState,
        factory: factoryState,
        format: formatState,
        basepack: basepackState,
        cascade: cascadeState,
        line: lineState,
        year: yearState,
        month: monthState,
      })
  );
  const data = await resp.json();

  return data;
}

export async function getCuData({
  demandFile,
  bgState,
  subcategoryState,
  branchState,
  brandState,
  formatState,
  basepackState,
  factoryState,
  yearState,
  quarterState,
  monthState,
  timeGranularity,
}) {
  const resp = await fetch(
    BASE_URL +
      "/cu_data?" +
      new URLSearchParams({
        demand_file: demandFile,
        bg: bgState,
        subcategory: subcategoryState,
        branch: branchState,
        brand: brandState,
        format: formatState,
        basepack: basepackState,
        factory: factoryState,
        year: yearState,
        quarter: quarterState,
        month: monthState,
        time_gran: timeGranularity,
      })
  );

  const data = await resp.json();

  return data;
}

export async function getAlertData({
  demandFile,
  bgState,
  subcategoryState,
  brandState,
  factoryState,
  formatState,
  yearState,
  quarterState,
  monthState,
}) {
  const resp = await fetch(
    BASE_URL +
      "/alert_data?" +
      new URLSearchParams({
        demand_file: demandFile,
        bg: bgState,
        subcategory: subcategoryState,
        brand: brandState,
        factory: factoryState,
        format: formatState,
        year: yearState,
        quarter: quarterState,
        month: monthState,
      })
  );
  const data = await resp.json();

  return data;
}

export async function getUploadInfo() {
  const resp = await fetch(BASE_URL + "/upload_info");
  const data = await resp.json();

  return data;
}

export async function uploadFile(filename, file) {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("filename", filename);

  const resp = await fetch(BASE_URL + "/upload_file", {
    method: "POST",
    body: formData,
  });

  const data = await resp.json();
  return data;
}

export async function getDemandData({
  demandFile,
  bgState,
  subcategoryState,
  branchState,
  clusterState,
  depotState,
  brandState,
  formatState,
  basepackState,
  factoryState,
  yearState,
  quarterState,
  monthState,
  timeGranularity,
}) {
  const resp = await fetch(
    BASE_URL +
      "/demand_data?" +
      new URLSearchParams({
        demand_file: demandFile,
        bg: bgState,
        subcategory: subcategoryState,
        branch: branchState,
        cluster: clusterState,
        depot: depotState,
        brand: brandState,
        format: formatState,
        basepack: basepackState,
        factory: factoryState,
        year: yearState,
        quarter: quarterState,
        month: monthState,
        time_gran: timeGranularity,
      })
  );

  const data = await resp.json();

  return data;
}

export async function getDemandL1Data({
  demandFile,
  bgState,
  subcategoryState,
  branchState,
  clusterState,
  depotState,
  brandState,
  formatState,
  basepackState,
  factoryState,
  yearState,
  quarterState,
  monthState,
  timeGranularity,
}) {
  const resp = await fetch(
    BASE_URL +
      "/demand_data_l1?" +
      new URLSearchParams({
        demand_file: demandFile,
        bg: bgState,
        subcategory: subcategoryState,
        branch: branchState,
        cluster: clusterState,
        depot: depotState,
        brand: brandState,
        format: formatState,
        basepack: basepackState,
        factory: factoryState,
        year: yearState,
        quarter: quarterState,
        month: monthState,
        time_gran: timeGranularity,
      })
  );

  const data = await resp.json();

  return data;
}

export async function getDemandL2Data({
  demandFile,
  bgState,
  subcategoryState,
  branchState,
  clusterState,
  depotState,
  brandState,
  formatState,
  basepackState,
  factoryState,
  yearState,
  quarterState,
  monthState,
  timeGranularity,
  format_l1,
}) {
  const resp = await fetch(
    BASE_URL +
      "/demand_data_l2?" +
      new URLSearchParams({
        demand_file: demandFile,
        bg: bgState,
        subcategory: subcategoryState,
        branch: branchState,
        cluster: clusterState,
        depot: depotState,
        brand: brandState,
        format: formatState,
        basepack: basepackState,
        factory: factoryState,
        year: yearState,
        quarter: quarterState,
        month: monthState,
        time_gran: timeGranularity,
        format_l1,
      })
  );

  const data = await resp.json();

  return data;
}
