import {
  Paper,
  Stack,
  Box,
  Select,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonBase,
  Menu,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  MenuItem,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DemandCapacityGraph from "../components/DemandCapacityGraph";
import { useNavigate } from "react-router-dom";
import ltcpRoutes from "../constants/ltcpRoutes";
import TuneIcon from "@mui/icons-material/Tune";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useContext, useEffect, useState } from "react";
import { LTCPContext } from "../LTCPContext";
import { getCuData } from "../api/ltcpBackend";

function ColorCell({ val }) {
  let color = "#FFCF5C"; // amber

  if (val > 90) color = "#FF6467"; // red
  else if (val < 80) color = "#33D0A3"; // green

  return <TableCell sx={{ background: color }}>{val}%</TableCell>;
}

function CUPage() {
  const navigate = useNavigate();
  const { demandFile, cuFilters, setLoading } = useContext(LTCPContext);
  const [filterDropdown, setFilterDropdown] = useState(null);
  const [cuConstrain, setCuConstrain] = useState("pack_unconstrained");
  const [timeGranularity, setTimeGranularity] = useState("MoM");

  const [bgState, setBgState] = useState("all");
  const [subcategoryState, setSubcategoryState] = useState("all");
  const [branchState, setBranchState] = useState("all");
  const [brandState, setBrandState] = useState("all");
  const [formatState, setFormatState] = useState("all");
  const [basepackState, setBasepackState] = useState("all");
  const [factoryState, setFactoryState] = useState("all");
  const [yearState, setYearState] = useState("all");
  const [quarterState, setQuarterState] = useState("all");
  const [monthState, setMonthState] = useState("all");
  const [result, setResult] = useState({
    date: [],
    demand: [],
    pack_cap_tpm: [],
    pack_cap_tpm_new: [],
    cu_const: [],
    cu_unconst: [],
  });

  function clearFilterHandler() {
    setBgState("all");
    setSubcategoryState("all");
    setBranchState("all");
    setBrandState("all");
    setFormatState("all");
    setBasepackState("all");
    setFactoryState("all");
  }

  async function fetchCuData() {
    setLoading(true);

    const data = await getCuData({
      demandFile,
      bgState,
      subcategoryState,
      branchState,
      brandState,
      formatState,
      basepackState,
      factoryState,
      yearState,
      quarterState,
      monthState,
      timeGranularity,
    });

    setResult(data);
    setLoading(false);
  }

  useEffect(() => {
    if (cuFilters.bg.length === 0) return navigate(ltcpRoutes.LANDING);

    fetchCuData();
  }, [
    bgState,
    subcategoryState,
    branchState,
    brandState,
    formatState,
    basepackState,
    factoryState,
    yearState,
    quarterState,
    monthState,
    timeGranularity,
  ]);

  return (
    <Stack p={4} gap={2} flexGrow={1}>
      <Stack
        gap={3}
        height={40}
        direction="row"
        alignItems="center"
        justifyContent="start"
      >
        <IconButton
          sx={{ color: "black" }}
          onClick={() => navigate(ltcpRoutes.DASHBOARD)}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
        <Typography fontWeight="bold" fontSize={21}>
          CU Utilization
        </Typography>
      </Stack>
      <Paper sx={{ height: 44 }}>
        <Stack
          px={2}
          direction="row"
          bgcolor="white"
          borderRadius="3px"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Stack px={3} gap={2} py={1} width="100%" direction="row">
            <Box display="flex" flexDirection="row" alignItems="center">
              <ButtonBase onClick={(e) => setFilterDropdown(e.currentTarget)}>
                <TuneIcon fontSize="small" />
                <Typography ml={1}>Filter</Typography>
                <KeyboardArrowDownIcon fontSize="small" />
              </ButtonBase>
              <Menu
                open={Boolean(filterDropdown)}
                anchorEl={filterDropdown}
                onClose={() => setFilterDropdown(null)}
              >
                <Stack p={1} gap={1} width={150}>
                  <Stack>
                    <Typography fontSize={12}>BG</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={bgState}
                      onChange={(e) => setBgState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.bg.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12}>Sub-Category</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={subcategoryState}
                      onChange={(e) => setSubcategoryState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.subcategory.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12}>Branch</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={branchState}
                      onChange={(e) => setBranchState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.branch.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12}>Brand</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={brandState}
                      onChange={(e) => setBrandState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.brand.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12}>Format L2</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={formatState}
                      onChange={(e) => setFormatState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.format.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12}>Basepack</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={basepackState}
                      onChange={(e) => setBasepackState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.basepack.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack>
                    <Typography fontSize={12}>Factory</Typography>
                    <Select
                      size="small"
                      sx={{ height: 28 }}
                      value={factoryState}
                      onChange={(e) => setFactoryState(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {cuFilters.factory.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Stack direction="row" justifyContent="end">
                    <Button variant="text" onClick={clearFilterHandler}>
                      Clear
                    </Button>
                    {/* <Button
                      size="small"
                      variant="contained"
                      onClick={() => setFilterDropdown(null)}
                    >
                      Apply
                    </Button> */}
                  </Stack>
                </Stack>
              </Menu>
            </Box>
            <Box gap={1} display="flex" flexDirection="row" alignItems="center">
              <Typography>Year</Typography>
              <Select
                size="small"
                sx={{ width: 100, height: 28 }}
                value={yearState}
                onChange={(e) => setYearState(e.target.value)}
              >
                <MenuItem value="all">All</MenuItem>
                {cuFilters.year.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box gap={1} display="flex" flexDirection="row" alignItems="center">
              <Typography>Quarter</Typography>
              <Select
                size="small"
                sx={{ width: 100, height: 28 }}
                value={quarterState}
                onChange={(e) => setQuarterState(e.target.value)}
                disabled={timeGranularity !== "QoQ"}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="Q1">Q1</MenuItem>
                <MenuItem value="Q2">Q2</MenuItem>
                <MenuItem value="Q3">Q3</MenuItem>
                <MenuItem value="Q4">Q4</MenuItem>
              </Select>
            </Box>
            <Box gap={1} display="flex" flexDirection="row" alignItems="center">
              <Typography>Month</Typography>
              <Select
                size="small"
                sx={{ width: 100, height: 28 }}
                value={monthState}
                onChange={(e) => setMonthState(e.target.value)}
                disabled={timeGranularity !== "MoM"}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="01">Jan</MenuItem>
                <MenuItem value="02">Feb</MenuItem>
                <MenuItem value="03">Mar</MenuItem>
                <MenuItem value="04">Apr</MenuItem>
                <MenuItem value="05">May</MenuItem>
                <MenuItem value="06">Jun</MenuItem>
                <MenuItem value="07">Jul</MenuItem>
                <MenuItem value="08">Aug</MenuItem>
                <MenuItem value="09">Sep</MenuItem>
                <MenuItem value="10">Oct</MenuItem>
                <MenuItem value="11">Nov</MenuItem>
                <MenuItem value="12">Dec</MenuItem>
              </Select>
            </Box>
            <Stack gap={1} direction="row" ml="auto">
              <Typography>CU% :</Typography>
              <Select
                size="small"
                sx={{ height: 28 }}
                value={cuConstrain}
                onChange={(e) => setCuConstrain(e.target.value)}
              >
                <MenuItem value="pack_unconstrained">
                  Pack Unconstrained
                </MenuItem>
                <MenuItem value="pack_constrained">Pack Constrained</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
      <Paper
        sx={{
          p: 1,
          height: "calc(100vh - 228px - 30%)",
          maxHeight: "calc(100vh - 228px - 30%)",
        }}
      >
        <Stack width="100%" direction="row" justifyContent="end">
          <ToggleButtonGroup
            value={timeGranularity}
            onChange={(_, v) => setTimeGranularity(v)}
            exclusive
          >
            <ToggleButton value="YoY">YoY</ToggleButton>
            <ToggleButton value="QoQ">QoQ</ToggleButton>
            <ToggleButton value="MoM">MoM</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <DemandCapacityGraph
          demand={result.demand}
          capacity={
            cuConstrain === "pack_constrained"
              ? result.pack_cap_tpm_new
              : result.pack_cap_tpm
          }
          cu={
            cuConstrain === "pack_constrained"
              ? result.cu_const
              : result.cu_unconst
          }
          labels={result.date}
        />
      </Paper>
      <Paper
        sx={{
          p: 1,
          height: "calc(100vh - 228px - 40%)",
          maxHeight: "calc(100vh - 228px - 40%)",
        }}
      >
        <TableContainer>
          <Table size="small">
            <TableHead sx={{ background: "#C5EAFB" }}>
              <TableRow>
                <TableCell>Element</TableCell>
                {result.date.map((item, index) => (
                  <TableCell key={index}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Demand</TableCell>
                {result.demand.map((item, index) => (
                  <TableCell key={index}>{item}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Capacity</TableCell>
                {(cuConstrain === "pack_constrained"
                  ? result.pack_cap_tpm_new
                  : result.pack_cap_tpm
                ).map((item, index) => (
                  <TableCell key={index}>{item}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>CU</TableCell>
                {(cuConstrain === "pack_constrained"
                  ? result.cu_const
                  : result.cu_unconst
                ).map((item, index) => (
                  <ColorCell key={index} val={item} />
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          mt={1}
          gap={1}
          direction="row"
          alignItems="center"
          justifyContent="end"
        >
          <Box sx={{ width: 12, height: 6, bgcolor: "#FF6467" }}></Box>
          <Typography fontSize={12}>{"CU > 90%"}</Typography>
          <Box sx={{ width: 12, height: 6, bgcolor: "#FFCF5C" }}></Box>
          <Typography fontSize={12}>{"CU between 80-90"}</Typography>
          <Box sx={{ width: 12, height: 6, bgcolor: "#33D0A3" }}></Box>
          <Typography fontSize={12}>{"CU <80"}</Typography>
        </Stack>
      </Paper>
    </Stack>
  );
}

export default CUPage;
