import React, { useState } from "react";
import { simulateScenarioJson } from "../../alert";
import styles from "./SimulateScenario.module.css";
import BarAndLineComboChart from '../../../../components/barAndLineComboChart/barAndLineComboChart';
import PeriodLineTab from "../../../../components/periodLineTab/periodLineTab";
import DemandTable from "../../../../components/table/demandTable";

const SimulateScenario = () => {
  const [selectedLine, setSelectedLine] = useState(simulateScenarioJson['Simulate data'].weeks[0]);
  const [tableValues, setTableValues] = useState(simulateScenarioJson['Simulate data'][simulateScenarioJson['Simulate data'].weeks[0]]);

  const horizontalLineHandler = (tabname) => {
    setTableValues(simulateScenarioJson['Simulate data'][tabname])
    setSelectedLine(tabname);
  };

  return (
    <div className={styles.simulateContainer}>
      <div className={styles.leftSimulateContainer}>
        <div className={styles.alertDes}>
          <span className={styles.alertDesTitle}>Alert Description</span>
          <span>{simulateScenarioJson["Alert Description"]}</span>
        </div>
        <div className={styles.alertOverview}>
          <span className={styles.header}>Current Scenario</span>
          {simulateScenarioJson["Current Scenario"].data.map((data, index) => (
            <div key={`${index}`} className={styles.cardContain}>
              <span className={styles.label}>{data.label}</span>
              <span className={styles.value}>
                <span className={styles.colon}>:</span>
                {data.value}
              </span>
            </div>
          ))}
        </div>
        <div className={styles.alertOverview}>
          <span className={styles.header}>Selected Scenario</span>
          {simulateScenarioJson["Selected Scenario"].data.map((data, index) => (
            <div key={`${index}`} className={styles.cardContain}>
              <span className={styles.label}>{data.label}</span>
              <span className={styles.value}>
                <span className={styles.colon}>:</span>
                {data.value}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rightSimulateContainer}>
        <span className={styles.demandTitle}>Demand Supply Gap</span>
        <PeriodLineTab selectedLine={selectedLine} horizontalLineHandler={horizontalLineHandler} values={simulateScenarioJson['Simulate data'].weeks}>
          <BarAndLineComboChart details={tableValues} type="simulate" />
          <DemandTable data={tableValues} type="simulate" />
        </PeriodLineTab>
      </div>
    </div>
  );
};

export default SimulateScenario;
