import React from "react";
import MainRouterHandler from "./routers";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate  } from '@azure/msal-react';
import Login from "./components/login/login";

const App = () => {
  const { accounts } = useMsal();
  const activeAccount = accounts[0] || null;

  return (
    <div>
      <MainRouterHandler />
      {/* <AuthenticatedTemplate>
        {activeAccount ? <MainRouterHandler /> : <Login />}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate> */}
    </div>
  );
}

export default App;
