import React, { useState } from 'react';
import styles from './DemandSupply.module.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InventoryStatus from '../../../../components/table/inventoryStatus';
import VerticalBarGraph from "../../../../components/verticalBarGraph/verticalBarGraph";
import SupplierLeadTimeTable from '../../../../components/table/supplierLeadTimeTable';
import ProjectedSupply from '../../../../components/table/projectedSupplyTable';

const KeyInsightsContent = ({ keyInsightContent, alertId, materialId, plantCode }) => {
    const [expanded, setExpanded] = useState('panel0');

    /**
     * Function to call Tab change
     * @param {*} panel 
     * @param {*} index 
     * @returns 
     */
    const handleChange = (panel, index) => (event, isExpanded) => {
        if (!isExpanded) {
            const nextPanel = `panel${index + 1}`;
            if (keyInsightContent[index + 1]) {
                setExpanded(nextPanel);
            } else if (index > 0) {
                setExpanded(`panel${index - 1}`);
            }
        } else {
            setExpanded(panel);
        }
    };

    return (
        <div className={styles.keyInsightsContainer}>
            {
                keyInsightContent.map((keyInsightData, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`, index)}
                        className={styles.accordionPanel}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            sx={{
                                minHeight: expanded === `panel${index}` ? '40px' : '40px',
                                '&.Mui-expanded': { minHeight: '40px' },
                                '.MuiAccordionSummary-content': {
                                    margin: expanded === `panel${index}` ? '0' : 'initial',
                                }
                            }}
                        >
                            <Typography sx={{
                                '@media (max-width: 1500px)': {
                                    fontSize: '13px',
                                },
                            }}>{keyInsightData.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordingContent}>
                            {
                                keyInsightData.question.includes("Site Wise Current Inventory") ?
                                    <InventoryStatus data={keyInsightData.data} alertId={alertId} materialId={materialId} plantCode={plantCode} /> :
                                    keyInsightData.question.includes("Lead time Master (In days)") ?
                                        <SupplierLeadTimeTable data={keyInsightData.data} alertId={alertId} materialId={materialId} plantCode={plantCode} /> :
                                        keyInsightData.question.includes("Inbound Supply Status") ?
                                            <ProjectedSupply data={keyInsightData.data} alertId={alertId} materialId={materialId} plantCode={plantCode} /> :
                                            <VerticalBarGraph data={keyInsightData.data} alertId={alertId} materialId={materialId} plantCode={plantCode} />
                            }
                        </AccordionDetails>

                    </Accordion>
                ))
            }
        </div>
    );
};

export default KeyInsightsContent;
