import {
  Box,
  Stack,
  Typography,
  Button,
  useTheme,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ltcpRoutes from "../constants/ltcpRoutes";
import { useContext, useEffect, useState } from "react";
import { LTCPContext } from "../LTCPContext";
import {
  getAlertFilters,
  getCapacityFilters,
  getCuFilters,
  getDemandFilters,
} from "../api/ltcpBackend.js";

function LandingPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [demandSelectMenu, setDemandSelectMenu] = useState(null);

  const {
    setLoading,
    setDemandFile,
    setCuFilters,
    setAlertFilters,
    setDemandFilters,
    setCapacityFilters,
  } = useContext(LTCPContext);

  async function fetchFilters() {
    setLoading(true);

    const resp = await Promise.all([
      getCuFilters(),
      getDemandFilters(),
      getCapacityFilters(),
      getAlertFilters(),
    ]);

    setCuFilters(resp[0]);
    setDemandFilters(resp[1]);
    setCapacityFilters(resp[2]);
    setAlertFilters(resp[3]);

    setLoading(false);
  }

  useEffect(() => {
    fetchFilters();
  }, []);

  return (
    <Box
      top={0}
      left={0}
      right={0}
      bottom={0}
      position={"fixed"}
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="stretch"
      bgcolor={theme.palette.primary.light}
      sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <Stack width="60%" justifyContent="center" alignItems="center">
        <Box
          gap={4}
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
        >
          <Typography
            fontWeight="bold"
            fontSize={56}
            sx={{ fontFamily: "arial !important" }}
          >
            Capacity Planning
          </Typography>
          <Stack gap={2}>
            <Stack gap={2} direction="row">
              <Button
                variant="contained"
                sx={{ width: 150 }}
                onClick={(e) => setDemandSelectMenu(e.currentTarget)}
              >
                Dashboard
                <ArrowDropDownIcon />
              </Button>
              <Menu
                open={Boolean(demandSelectMenu)}
                anchorEl={demandSelectMenu}
                onClose={() => setDemandSelectMenu(null)}
              >
                <MenuItem
                  sx={{ background: "#C5EAFB", mx: 1 }}
                  onClick={() => {
                    setDemandFile("V1_Demand");
                    navigate(ltcpRoutes.DASHBOARD);
                  }}
                >
                  Dashboard - V1 demand file
                </MenuItem>
                <MenuItem
                  sx={{ background: "#C5EAFB", mx: 1, my: 0.5 }}
                  onClick={() => {
                    setDemandFile("M1_Demand");
                    navigate(ltcpRoutes.DASHBOARD);
                  }}
                >
                  Dashboard - Manual 1 demand file
                </MenuItem>
                <MenuItem
                  sx={{ background: "#C5EAFB", mx: 1 }}
                  onClick={() => {
                    setDemandFile("M2_Demand");
                    navigate(ltcpRoutes.DASHBOARD);
                  }}
                >
                  Dashboard - Manual 2 demand file
                </MenuItem>
              </Menu>
              <Button
                variant="contained"
                sx={{ width: 150 }}
                onClick={() => navigate(ltcpRoutes.UPLOADS)}
              >
                Uploads
              </Button>
            </Stack>
            <Stack gap={2} direction="row">
              <Button
                variant="contained"
                sx={{ width: 150 }}
                onClick={() => navigate(ltcpRoutes.SIMULATION)}
              >
                Simulation
              </Button>
              <Button
                variant="contained"
                sx={{ width: 150 }}
                onClick={() => navigate(ltcpRoutes.REPORTS)}
              >
                Reports
              </Button>
            </Stack>
          </Stack>
        </Box>
        <img
          src="/cp_illustration.png"
          style={{
            position: "absolute",
            height: '250px',
            width: '480px',
            objectFit: "scale-down",
            right: '200px',
            bottom: '30px',
            zIndex: 9999
          }}
        />
      </Stack>
      <Stack
        width="40%"
        sx={{
          position: "relative",
          background: `linear-gradient(to bottom, #1F36C7, #321071)`,
          clipPath: "polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)",
        }}
        alignItems="end"
      >
        <img
          src="/unilever_logo.svg"
          height={32}
          style={{ position: "absolute", top: 25, right: 25 }}
        />
      </Stack>
    </Box>
  );
}

export default LandingPage;
