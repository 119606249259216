const ltcpRoutes = {
  LANDING: "/ltcp/landing",
  DASHBOARD: "/ltcp/dashboard/overview",
  UPLOADS: "/ltcp/dashboard/uploads",
  SIMULATION: "/ltcp/dashboard/simulation",
  REPORTS: "/ltcp/dashboard/reports",
  CU: "/ltcp/dashboard/cu",
  DEMAND: "/ltcp/dashboard/demand",
  CAPACITY: "/ltcp/dashboard/capacity",
};

export default ltcpRoutes;
