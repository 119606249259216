import { Button, Paper, Select, Stack, Typography } from "@mui/material";

function ReportCard({ index, title, body, bgcolor, onClick }) {
  return (
    <Stack p={4} py={3} gap={1} bgcolor={bgcolor} sx={{ borderRadius: 2 }}>
      <img src={`/grid_${index}.svg`} width={40} style={{ marginBottom: 12 }} />
      <Typography fontSize={18} fontWeight="bold">
        {title}
      </Typography>
      <Typography fontSize={12}>{body}</Typography>
      <Button variant="contained" onClick={onClick} sx={{ width: 150, mt: 4 }}>
        Generate Report
      </Button>
    </Stack>
  );
}

function ReportPage() {
  return (
    <Stack p={4} gap={2} flexGrow={1} bgcolor="#fff">
      <Stack
        px={2}
        py={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontSize={21} fontWeight="bold">
          Reporting
        </Typography>
        <Stack gap={1} direction="row" alignItems="center">
          <Stack width={130}>
            <Typography fontSize={12}>BG</Typography>
            <Select size="small" sx={{ height: 28 }}></Select>
          </Stack>
          <Stack width={130}>
            <Typography fontSize={12}>Sub-Category</Typography>
            <Select size="small" sx={{ height: 28 }}></Select>
          </Stack>
          <Stack width={130}>
            <Typography fontSize={12}>Factory</Typography>
            <Select size="small" sx={{ height: 28 }}></Select>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        gap={1.5}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <ReportCard
          index={1}
          bgcolor="#DEF6FF"
          title="Capacity Utilisation"
          body="Report provides insights on capacity utilisation and helps to visualise how demand meets capacity enabling strategic decisions at India, Branch and Factory levels."
        />
        <ReportCard
          index={2}
          bgcolor="#E2E7FF"
          title="Demand Summary"
          body="Report provides a comprehensive overview of demand across product hierarchies, with growth percentages to show case trends and performance over time."
        />
        <ReportCard
          index={3}
          bgcolor="#FFDEEB"
          title="Capacity Summary"
          body="Report provides details about shifts allocated for Make and Pack processes based on the demand, calculated using basepack priority rules."
        />
      </Stack>
    </Stack>
  );
}

export default ReportPage;
