import {
  IconButton,
  Stack,
  Typography,
  Paper,
  Select,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonBase,
  ToggleButtonGroup,
  ToggleButton,
  MenuItem,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ltcpRoutes from "../constants/ltcpRoutes";
import ReactECharts from "echarts-for-react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useContext, useEffect, useState } from "react";
import { LTCPContext } from "../LTCPContext";
import {
  getDemandData,
  getDemandL1Data,
  getDemandL2Data,
} from "../api/ltcpBackend";

function ColorCell({ val }) {
  let color = "#FFCF5C"; // amber

  if (val < 0) color = "#FF6467"; // red
  else if (val > 10) color = "#33D0A3"; // green

  return <TableCell sx={{ background: color }}>{val}%</TableCell>;
}

function NestedRowL1({
  data,
  header,
  bgState,
  subcategoryState,
  branchState,
  clusterState,
  depotState,
  brandState,
  formatState,
  basepackState,
  factoryState,
  yearState,
  quarterState,
  monthState,
  timeGranularity,
}) {
  const { demandFile, setLoading } = useContext(LTCPContext);
  const [nestedData, setNestedData] = useState([]);

  async function fetchDemandL1Data() {
    setLoading(true);

    const rows = await getDemandL1Data({
      demandFile,
      bgState,
      subcategoryState,
      branchState,
      clusterState,
      depotState,
      brandState,
      formatState,
      basepackState,
      factoryState,
      yearState,
      quarterState,
      monthState,
      timeGranularity,
    });

    setNestedData([...rows]);
    setLoading(false);
  }

  return (
    <>
      <TableRow>
        <TableCell sx={{ pl: 0 }}>
          <ButtonBase
            onClick={() => {
              if (nestedData.length > 0) {
                setNestedData([]);
              } else {
                fetchDemandL1Data();
              }
            }}
          >
            {nestedData.length > 0 ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
            <Typography fontSize={12}>{data.brand}</Typography>
          </ButtonBase>
        </TableCell>
        {header.map((item, index) =>
          item.startsWith("growth") ? (
            <ColorCell key={index} val={data[item]} />
          ) : (
            <TableCell key={index}>{data[item]}</TableCell>
          )
        )}
        {data.cagr && <ColorCell val={data.cagr} />}
      </TableRow>
      {nestedData.map((item, index) => (
        <NestedRowL2
          key={index}
          data={item}
          header={header}
          bgState={bgState}
          subcategoryState={subcategoryState}
          branchState={branchState}
          clusterState={clusterState}
          depotState={depotState}
          brandState={brandState}
          formatState={formatState}
          basepackState={basepackState}
          factoryState={factoryState}
          yearState={yearState}
          quarterState={quarterState}
          monthState={monthState}
          timeGranularity={timeGranularity}
        />
      ))}
    </>
  );
}

function NestedRowL2({
  data,
  header,
  bgState,
  subcategoryState,
  branchState,
  clusterState,
  depotState,
  brandState,
  formatState,
  basepackState,
  factoryState,
  yearState,
  quarterState,
  monthState,
  timeGranularity,
}) {
  const { demandFile, setLoading } = useContext(LTCPContext);
  const [nestedData, setNestedData] = useState([]);

  async function fetchDemandL2Data() {
    setLoading(true);

    const rows = await getDemandL2Data({
      demandFile,
      bgState,
      subcategoryState,
      branchState,
      clusterState,
      depotState,
      brandState,
      formatState,
      basepackState,
      factoryState,
      yearState,
      quarterState,
      monthState,
      timeGranularity,
      format_l1: data.formatsL1,
    });

    setNestedData(rows);
    setLoading(false);
  }

  return (
    <>
      <TableRow>
        <TableCell sx={{ pl: 1 }}>
          <ButtonBase
            onClick={() => {
              if (nestedData.length > 0) {
                setNestedData([]);
              } else {
                fetchDemandL2Data();
              }
            }}
          >
            {nestedData.length > 0 ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
            <Typography fontSize={12}>{data.formatsL1}</Typography>
          </ButtonBase>
        </TableCell>
        {header.map((item, index) =>
          item.startsWith("growth") ? (
            <ColorCell val={data[item]} />
          ) : (
            <TableCell key={index}>{data[item]}</TableCell>
          )
        )}
        {data.cagr && <ColorCell val={data.cagr} />}
      </TableRow>
      {nestedData.map((item, index) => (
        <TableRow key={index}>
          <TableCell sx={{ fontSize: 12 }}>{item.formatsL2}</TableCell>
          {header.map((hdr, index) =>
            hdr.startsWith("growth") ? (
              <ColorCell val={item[hdr]} />
            ) : (
              <TableCell key={index}>{item[hdr]}</TableCell>
            )
          )}
          {item.cagr && <ColorCell val={item.cagr} />}
        </TableRow>
      ))}
    </>
  );
}

function DemandPage() {
  const navigate = useNavigate();
  const { demandFile, demandFilters, setLoading } = useContext(LTCPContext);
  const [timeGranularity, setTimeGranularity] = useState("MoM");
  const [bgState, setBgState] = useState("all");
  const [subcategoryState, setSubcategoryState] = useState("all");
  const [branchState, setBranchState] = useState("all");
  const [clusterState, setClusterState] = useState("all");
  const [depotState, setDepotState] = useState("all");
  const [brandState, setBrandState] = useState(demandFilters.brand[0]);
  const [formatState, setFormatState] = useState("all");
  const [basepackState, setBasepackState] = useState("all");
  const [factoryState, setFactoryState] = useState("all");
  const [yearState, setYearState] = useState("all");
  const [quarterState, setQuarterState] = useState("all");
  const [monthState, setMonthState] = useState("all");
  const [result, setResult] = useState({
    graph: {
      labels: [],
      growth: [],
      bars: [],
    },
    table: {
      header: [],
      data: [],
    },
  });

  let option = {
    // tooltip: {
    //   trigger: "axis",
    //   axisPointer: {
    //     type: "cross",
    //     crossStyle: {
    //       color: "#999",
    //     },
    //   },
    // },
    legend: {
      selectedMode: false,
      top: "bottom",
    },
    grid: {
      left: 50,
      right: 50,
      top: 30,
      bottom: 80,
    },
    yAxis: [
      {
        axisLabel: {
          formatter: "{value}%",
        },
        type: "value",
        splitLine: {
          show: false,
        },
      },
      {
        axisLabel: {
          formatter: "{value}%",
        },
        type: "value",
        splitLine: {
          show: false,
        },
      },
    ],
    xAxis: {
      type: "category",
      splitLine: {
        show: false,
      },
      data: result.graph.labels,
    },
    series: [
      ...result.graph.bars.map((item) => ({
        name: item.name,
        type: "bar",
        stack: "total",
        barWidth: "50%",
        data: item.data,
      })),
      {
        type: "line",
        name: "YoY Growth%",
        data: result.graph.growth,
        yAxisIndex: 1,
        color: "#FFBD65",
        tooltip: {
          valueFormatter: function (value) {
            return value + "%";
          },
        },
        label: {
          show: true,
          position: "top",
          formatter: "{c}%",
        },
      },
    ],
    color: ["#1F36C7", "#01B2FE", "#4C64FF", "#846CE2", "#C46CE2"],
  };

  async function fetchDemandData() {
    setLoading(true);

    const data = await getDemandData({
      demandFile,
      bgState,
      subcategoryState,
      branchState,
      clusterState,
      depotState,
      brandState,
      formatState,
      basepackState,
      factoryState,
      yearState,
      quarterState,
      monthState,
      timeGranularity,
    });

    setResult(data);
    setLoading(false);
  }

  useEffect(() => {
    if (demandFilters.bg.length === 0) return navigate(ltcpRoutes.LANDING);

    fetchDemandData();
  }, [
    bgState,
    subcategoryState,
    branchState,
    clusterState,
    depotState,
    brandState,
    formatState,
    basepackState,
    factoryState,
    yearState,
    quarterState,
    monthState,
    timeGranularity,
  ]);

  return (
    <Stack p={4} gap={2}>
      <Stack direction="row" alignItems="center">
        <IconButton
          sx={{ color: "black" }}
          onClick={() => navigate(ltcpRoutes.DASHBOARD)}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
        <Typography fontSize={21} fontWeight="bold">
          Demand Summary
        </Typography>
      </Stack>
      <Paper>
        <Stack
          py={1}
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Stack width="7.8%">
            <Typography fontSize={12}>BG</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={bgState}
              onChange={(e) => setBgState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {demandFilters.bg.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>Sub-Category</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={subcategoryState}
              onChange={(e) => setSubcategoryState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {demandFilters.subcategory.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>Branch</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={branchState}
              onChange={(e) => setBranchState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {demandFilters.branch.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>Cluster</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={clusterState}
              onChange={(e) => setClusterState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {demandFilters.cluster.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>Depot</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={depotState}
              onChange={(e) => setDepotState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {demandFilters.depot.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>
              Brand<span style={{ color: "red" }}> *</span>
            </Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={brandState}
              onChange={(e) => setBrandState(e.target.value)}
            >
              {/* <MenuItem value="all">All</MenuItem> */}
              {demandFilters.brand.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>Format L2</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={formatState}
              onChange={(e) => setFormatState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {demandFilters.format.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>Basepack</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={basepackState}
              onChange={(e) => setBasepackState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {demandFilters.basepack.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>Factory</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={factoryState}
              onChange={(e) => setFactoryState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {demandFilters.factory.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>Year</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={yearState}
              onChange={(e) => setYearState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {demandFilters.year.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>Quarter</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={quarterState}
              onChange={(e) => setQuarterState(e.target.value)}
              disabled={timeGranularity === "MoM" || timeGranularity === "YoY"}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="Q1">Q1</MenuItem>
              <MenuItem value="Q2">Q2</MenuItem>
              <MenuItem value="Q3">Q3</MenuItem>
              <MenuItem value="Q4">Q4</MenuItem>
            </Select>
          </Stack>
          <Stack width="7.8%">
            <Typography fontSize={12}>Month</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={monthState}
              onChange={(e) => setMonthState(e.target.value)}
              disabled={timeGranularity === "QoQ" || timeGranularity === "YoY"}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="01">Jan</MenuItem>
              <MenuItem value="02">Feb</MenuItem>
              <MenuItem value="03">Mar</MenuItem>
              <MenuItem value="04">Apr</MenuItem>
              <MenuItem value="05">May</MenuItem>
              <MenuItem value="06">Jun</MenuItem>
              <MenuItem value="07">Jul</MenuItem>
              <MenuItem value="08">Aug</MenuItem>
              <MenuItem value="09">Sep</MenuItem>
              <MenuItem value="10">Oct</MenuItem>
              <MenuItem value="11">Nov</MenuItem>
              <MenuItem value="12">Dec</MenuItem>
            </Select>
          </Stack>
        </Stack>
      </Paper>
      <Paper>
        <Stack
          p={1}
          gap={1}
          direction="row"
          alignItems="center"
          justifyContent="end"
        >
          <ToggleButtonGroup
            value={timeGranularity}
            onChange={(_, v) => setTimeGranularity(v)}
            exclusive
          >
            <ToggleButton value="YoY">YoY</ToggleButton>
            <ToggleButton value="QoQ">QoQ</ToggleButton>
            <ToggleButton value="MoM">MoM</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <ReactECharts
          option={option}
          style={{ width: "100%", height: "40vh" }}
        />
      </Paper>
      <Paper sx={{ p: 1 }}>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ background: "#C5EAFB" }}></TableCell>
                <TableCell
                  colSpan={result.table.header.length / 2}
                  sx={{
                    background: "#C5EAFB",
                    borderLeft: "1px solid #fff",
                    textAlign: "center",
                  }}
                >
                  Demand
                </TableCell>
                <TableCell
                  colSpan={result.table.header.length / 2}
                  sx={{
                    background: "#C5EAFB",
                    borderLeft: "1px solid #fff",
                    textAlign: "center",
                  }}
                >
                  {timeGranularity} Growth
                </TableCell>
                {timeGranularity === "YoY" && (
                  <TableCell
                    sx={{
                      background: "#C5EAFB",
                      borderLeft: "1px solid #fff",
                      textAlign: "center",
                    }}
                  ></TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell sx={{ background: "#C5EAFB" }}>
                  Product Format
                </TableCell>
                {result.table.header.map((item, index) => (
                  <TableCell
                    sx={{ background: "#C5EAFB", borderLeft: "1px solid #fff" }}
                    key={index}
                  >
                    {item.split("_")[1]}
                  </TableCell>
                ))}
                {timeGranularity === "YoY" && (
                  <TableCell
                    sx={{ background: "#C5EAFB", borderLeft: "1px solid #fff" }}
                  >
                    CAGR 2Y
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {result.table.data.map((item, index) => (
                <NestedRowL1
                  key={index}
                  data={item}
                  header={result.table.header}
                  bgState={bgState}
                  subcategoryState={subcategoryState}
                  branchState={branchState}
                  clusterState={clusterState}
                  depotState={depotState}
                  brandState={brandState}
                  formatState={formatState}
                  basepackState={basepackState}
                  factoryState={factoryState}
                  yearState={yearState}
                  quarterState={quarterState}
                  monthState={monthState}
                  timeGranularity={timeGranularity}
                />
              ))}
              {/* <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Grand Total</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>123</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>123</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>123</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>123</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>10%</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>10%</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>10%</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>10%</TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          mt={2}
          gap={1}
          direction="row"
          alignItems="center"
          justifyContent="end"
        >
          <Box sx={{ width: 12, height: 12, background: "#FF6467" }}></Box>
          <Typography mr={1} fontSize={12}>
            {"< 0"}
          </Typography>
          <Box sx={{ width: 12, height: 12, background: "#FFCF5C" }}></Box>
          <Typography mr={1} fontSize={12}>
            {"0 - 10"}
          </Typography>
          <Box sx={{ width: 12, height: 12, background: "#33D0A3" }}></Box>
          <Typography mr={1} fontSize={12}>
            {"> 10"}
          </Typography>
        </Stack>
      </Paper>
    </Stack>
  );
}

export default DemandPage;
