import {
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ltcpRoutes from "../constants/ltcpRoutes";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ReactECharts from "echarts-for-react";
import { useContext, useEffect, useState } from "react";
import { LTCPContext } from "../LTCPContext";
import { getCapacityData } from "../api/ltcpBackend";

function LineChart({ labels, data }) {
  const option = {
    title: {
      text: "Line Chart with Area Fill",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: labels,
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: "Sales",
        type: "line",
        data,
        lineStyle: {
          color: "#846CE2", // Line color
          width: 2, // Line width
        },
        areaStyle: {
          color: "#846CE233", // Fill area with semi-transparent color
        },
        itemStyle: {
          color: "#5470C6", // Point color
        },
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ width: "100%", height: "100%" }} />
  );
}

function BarChart({ labels, data }) {
  const option = {
    grid: {
      top: 30,
      left: 50,
      right: 20,
      bottom: 50,
    },
    xAxis: {
      type: "category",
      data: labels,
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: "Sales",
        type: "bar",
        barWidth: "50%",
        itemStyle: {
          color: "#01B2FE",
        },
        data,
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ width: "100%", height: "100%" }} />
  );
}

function CapacityPage() {
  const navigate = useNavigate();
  const { capacityFilters, setLoading } = useContext(LTCPContext);

  const [bgState, setBgState] = useState("all");
  const [subcategoryState, setSubcategoryState] = useState("all");
  const [factoryState, setFactoryState] = useState(capacityFilters.factory[0]);
  const [formatState, setFormatState] = useState(capacityFilters.format[0]);
  const [basepackState, setBasepackState] = useState(
    capacityFilters.basepack[0]
  );
  const [cascadeState, setCascadeState] = useState(capacityFilters.cascade[0]);
  const [lineState, setLineState] = useState(capacityFilters.line[0]);
  const [yearState, setYearState] = useState("all");
  const [monthState, setMonthState] = useState(0);

  const [result, setResult] = useState([]);

  async function fetchCapacityData() {
    setLoading(true);

    const data = await getCapacityData({
      bgState,
      subcategoryState,
      factoryState,
      formatState,
      basepackState,
      cascadeState,
      lineState,
      yearState,
      monthState,
    });

    setResult(data);
    setLoading(false);
  }

  useEffect(() => {
     fetchCapacityData();
  }, [
    bgState,
    subcategoryState,
    factoryState,
    formatState,
    basepackState,
    cascadeState,
    lineState,
    yearState,
    monthState,
  ]);

  return (
    <Stack p={4} gap={2}>
      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => navigate(ltcpRoutes.DASHBOARD)}
          sx={{ color: "#000" }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
        <Typography fontSize={21} fontWeight="bold">
          Capacity Planning
        </Typography>
      </Stack>
      <Paper>
        <Stack
          py={1}
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Stack width="10%">
            <Typography fontSize={12}>BG</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={bgState}
              onChange={(e) => setBgState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {capacityFilters.bg.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="10%">
            <Typography fontSize={12}>Sub-Category</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={subcategoryState}
              onChange={(e) => setSubcategoryState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {capacityFilters.subcategory.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="10%">
            <Typography fontSize={12}>
              Factory<span style={{ color: "red" }}> *</span>
            </Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={factoryState}
              onChange={(e) => setFactoryState(e.target.value)}
            >
              {capacityFilters.factory.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="10%">
            <Typography fontSize={12}>
              Format L2<span style={{ color: "red" }}> *</span>
            </Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={formatState}
              onChange={(e) => setFormatState(e.target.value)}
            >
              {capacityFilters.format.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="10%">
            <Typography fontSize={12}>
              Basepack<span style={{ color: "red" }}> *</span>
            </Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={basepackState}
              onChange={(e) => setBasepackState(e.target.value)}
            >
              {capacityFilters.basepack.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="10%">
            <Typography fontSize={12}>
              Cascade<span style={{ color: "red" }}> *</span>
            </Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={cascadeState}
              onChange={(e) => setCascadeState(e.target.value)}
            >
              {capacityFilters.cascade.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="10%">
            <Typography fontSize={12}>
              Line<span style={{ color: "red" }}> *</span>
            </Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={lineState}
              onChange={(e) => setLineState(e.target.value)}
            >
              {capacityFilters.line.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="10%">
            <Typography fontSize={12}>Year</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={yearState}
              onChange={(e) => setYearState(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {capacityFilters.year.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack width="10%">
            <Typography fontSize={12}>Month</Typography>
            <Select
              size="small"
              sx={{ height: 26 }}
              value={monthState}
              onChange={(e) => setMonthState(Number.parseInt(e.target.value))}
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
              <MenuItem value={12}>12</MenuItem>
            </Select>
          </Stack>
        </Stack>
      </Paper>
      <Stack
        gap={2}
        direction="row"
        height="35vh"
        minHeight="35vh"
        maxHeight="35vh"
      >
        <Paper
          sx={{
            p: 1,
            width: "calc(50% - 24px)",
            maxWidth: "calc(50% - 24px)",
            minWidth: "calc(50% - 24px)",
          }}
        >
          <Typography fontWeight="bold" fontSize={16}>
            BCT
          </Typography>
          <BarChart
            labels={result.map((item) => item.year)}
            data={result.map((item) => item.bct)}
          />
        </Paper>
        <Paper
          sx={{
            p: 1,
            width: "calc(50% - 24px)",
            maxWidth: "calc(50% - 24px)",
            minWidth: "calc(50% - 24px)",
          }}
        >
          <Typography fontWeight="bold" fontSize={16}>
            Line Speed
          </Typography>
          <LineChart
            labels={result.map((item) => item.year)}
            data={result.map((item) => item.line_speed)}
          />
        </Paper>
      </Stack>
      <Stack
        gap={2}
        direction="row"
        height="35vh"
        minHeight="35vh"
        maxHeight="35vh"
      >
        <Paper
          sx={{
            p: 1,
            width: "calc(50% - 24px)",
            maxWidth: "calc(50% - 24px)",
            minWidth: "calc(50% - 24px)",
          }}
        >
          <Typography fontWeight="bold" fontSize={16}>
            Line OEE
          </Typography>
          <LineChart
            labels={result.map((item) => item.year)}
            data={result.map((item) => item.line_oee)}
          />
        </Paper>
        <Paper
          sx={{
            p: 1,
            width: "calc(50% - 24px)",
            maxWidth: "calc(50% - 24px)",
            minWidth: "calc(50% - 24px)",
          }}
        >
          <Typography fontWeight="bold" fontSize={16}>
            Anual Pack Capacity
          </Typography>
          <BarChart
            labels={result.map((item) => item.year)}
            data={result.map((item) => item.actual_pack_cap)}
          />
        </Paper>
      </Stack>
      <Paper sx={{ p: 1 }}>
        <TableContainer sx={{ maxHeight: "20vh", overflowY: "auto" }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ background: "#C5EAFB" }}>Year</TableCell>
                <TableCell sx={{ background: "#C5EAFB" }}>Site</TableCell>
                <TableCell sx={{ background: "#C5EAFB" }}>Format</TableCell>
                <TableCell sx={{ background: "#C5EAFB" }}>Basepack</TableCell>
                <TableCell sx={{ background: "#C5EAFB" }}>Cascade No</TableCell>
                <TableCell sx={{ background: "#C5EAFB" }}>Line</TableCell>
                <TableCell sx={{ background: "#C5EAFB" }}>BCT</TableCell>
                <TableCell sx={{ background: "#C5EAFB" }}>Line Speed</TableCell>
                <TableCell sx={{ background: "#C5EAFB" }}>Line OEE</TableCell>
                <TableCell sx={{ background: "#C5EAFB" }}>
                  Actual Pack Cap
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.year}</TableCell>
                  <TableCell>{item.site}</TableCell>
                  <TableCell>{item.format}</TableCell>
                  <TableCell>{item.basepack}</TableCell>
                  <TableCell>{item.cascade}</TableCell>
                  <TableCell>{item.line}</TableCell>
                  <TableCell>{item.bct}</TableCell>
                  <TableCell>{item.line_speed}</TableCell>
                  <TableCell>{item.line_oee}</TableCell>
                  <TableCell>{item.actual_pack_cap}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
}

export default CapacityPage;
